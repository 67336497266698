import React, { useContext } from "react";
import { createContext } from "react";
import { useNotification } from "../Notification/NotificationContext";
import Php from "../../Backend/Php";
import { loginApi } from "../../Axios/apis";
const php = new Php();

const UserContext = createContext({
  handleForm: () => {},
});
export const useUserContext = () => useContext(UserContext);
export default function LoginProvider({ children }) {
  const { addNotification } = useNotification();

  const [formData, setFormData] = React.useState({
    server: "",
    username: "",
    password: "",
  });
  const [loading, setLoading] = React.useState(false);

  const handleInputs = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleLogin = async (e) => {
    // e.preventDefault(); // Prevent the default form submission
    const { server, password, username } = formData;
    // if (server === "") {
    //   addNotification("Enter Server", "error");
    //   return;
    // }
    // if (username === "") {
    //   addNotification("Enter Username", "error");
    //   return;
    // }
    // if (password === "") {
    //   addNotification("Enter Password", "error");
    //   return;
    // }
    // Continue with your login logic
    let data = {
      sr: server,
      un: username,
      pw: password,
    };
    // setLoading(true);
    const r = await loginApi(data);
    if (r.error == "False") {
      addNotification(r.message, "success");
      localStorage.setItem("isLoggedB2CAdmin", "1");
      localStorage.setItem("token", r.jwt);
      localStorage.setItem("accType", r.acc_type);
      localStorage.setItem("server", r.sv);
      localStorage.setItem("adminsId", r.admins_id);
      localStorage.setItem("adminsDetails", JSON.stringify(r.admins_details));
      localStorage.setItem("adminsUsername", r.username);
      localStorage.setItem("selectedWachlist", "fut");
      // window.location = "/";
      window.location.href = "/home";
    } else {
      addNotification(r.message, "error");
    }
    // });
  };

  return (
    <UserContext.Provider
      value={{
        formData,
        loading,
        setLoading,
        handleInputs,
        handleLogin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
