import React, { useState } from "react";
import styled from "styled-components";
import { NewTheme } from "../Theme/Theme";
import Edit from "./Edit/Edit";
import LedgerLogs from "./Ledger/LedgerLogs";
import LoginLogs from "./LoginLogs/LoginLogs";
import Overview from "./Overview/Overview";
import Script from "./Script/Script";

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black;
`;

const Tabs = styled.div`
  display: flex;
  background-color: white;
  // border-top: 1px solid gray;
  // border-left: 1px solid gray;
  border-bottom: 1px solid gray;
  // border-right: 1px solid gray;
`;

const Tab = styled.div`
  padding: 15px 30px;
  color: black;
  cursor: pointer;
  border-right: 1px solid gray;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};

  ${(props) =>
    props.active &&
    `
    background-color: ${NewTheme.lightmain};
    color: ${NewTheme.MainColor};
    border-color: ${NewTheme.MainColor};
    border-bottom: 3px solid ${NewTheme.MainColor}; 
  `}
`;

const TabContent = styled.div`
  padding: 10px;
`;

function RightSide(props) {
  const [activeTab, setActiveTab] = useState("Overview"); // Set the initial active tab

  props.openRef.current = () => {
    setActiveTab("Overview");
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <RightPanelContainer>
      <Tabs>
        <Tab
          active={activeTab === "Overview"}
          onClick={() => handleTabClick("Overview")}
        >
          Overview
        </Tab>
        <Tab
          active={activeTab === "Edit"}
          onClick={() => handleTabClick("Edit")}
        >
          Edit
        </Tab>
        <Tab
          active={activeTab === "Ledger"}
          onClick={() => handleTabClick("Ledger")}
        >
          Ledger
        </Tab>
        <Tab
          active={activeTab === "History"}
          onClick={() => handleTabClick("History")}
        >
          Login Logs
        </Tab>
        <Tab
          active={activeTab === "Scrips"}
          onClick={() => handleTabClick("Scrips")}
        >
          Scripts
        </Tab>
      </Tabs>
      <TabContent>
        {/* Render content based on the active tab */}
        {activeTab === "Overview" && (
          <Overview
            reload={() => props.reload()}
            key={props.data.id}
            data={props.data}
          />
        )}
        {activeTab === "Edit" && (
          <Edit reload={() => props.reload()} data={props.data} />
        )}
        {activeTab === "Ledger" && <LedgerLogs data={props.data} />}
        {activeTab === "History" && <LoginLogs data={props.data} />}
        {activeTab === "Scrips" && <Script data={props.data} />}
      </TabContent>
    </RightPanelContainer>
  );
}

export default RightSide;
