import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";

import { NewTheme } from "../../Theme/Theme";
import { BoldText, FlexContainer, FlexItem, GrayText } from "../Styled";

const php = new Php();

export default function VolumeModal(props) {
  const { addNotification } = useNotification();

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>{"Volume Summary "}</div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <>
        <FlexContainer>
          <FlexItem>
            <GrayText>Future Volume</GrayText>
            <BoldText>{props.futVolume}</BoldText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Mcx Lot Wise</GrayText>
            <BoldText>{props.mcxLot + " Lot"}</BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Mcx Volume Wise</GrayText>
            <BoldText>{props.mcxvolume}</BoldText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Option Volume</GrayText>
            <BoldText>{props.foVolume + " Lot"}</BoldText>
          </FlexItem>
        </FlexContainer>
      </>
    </Dialog>
  );
}
