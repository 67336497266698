import React from "react";
import styled, { css } from "styled-components";
import { NewTheme } from "../../Theme/Theme";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";

const php = new Php();

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black; /* Add a 1px black border on the left side */
`;

export const BalanceWrapper = styled.div`
  /* border-bottom: 1px solid lightgray; */
  /* margin-bottom: 20px; */
  display: flex; /* Make child elements horizontal */
  flex-direction: column;
  width: 90%;
  justify-content: space-between; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
  justify-content: space-between; /* Add space between children */
`;

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${NewTheme.MainColor};
      font-weight: bold;
    `}
`;

export const BalanceTextArea = styled.textarea`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${NewTheme.MainColor};
      font-weight: bold;
    `}
`;

export const CreateClientButton = styled.button`
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s ease;

  ${(props) =>
    props.active
      ? css`
          border: 1px solid ${props.color ? props.color : "#3959ab"};
          background-color: ${props.color ? props.color : "#3959ab"};
          color: #fff;
        `
      : css`
          background: none;
          color: ${props.color ? props.color : "#3959ab"};
          border: 1px solid ${props.color ? props.color : "#3959ab"};
        `}
`;

export const BalanceUpdateButton = styled.button`
  display: flex; /* Center content horizontally and vertically */
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: white;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  background-color: ${NewTheme.MainColor};
  align-self: center;
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
  text-align: left;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;

export default function AddUser(props) {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();

  const [name, setName] = React.useState("");
  const [username, setUserame] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [balance, setBalance] = React.useState(0);
  const [mcxVolumeWise, setMcxVolumeWise] = React.useState(0);

  const [futBrokerage, setFutBrokerage] = React.useState("0.02");
  const [mcxBrokerage, setMcxBrokerage] = React.useState(50);
  const [foBrokerage, setFoBrokerage] = React.useState(25);

  const [forexBrokerage, setForexBrokerage] = React.useState("0");
  const [cryptoBrokerage, setCryptoBrokerage] = React.useState("0");
  const [stocksBrokerage, setStocksBrokerage] = React.useState("0");

  const [brokerList, setBrokerList] = React.useState([]);
  const [brokerId, setBrokerId] = React.useState(0);

  const [brokerFutBrokerage, setBrokerFutBrokerage] = React.useState(0);
  const [brokerFutBrokeragePercent, setBrokerFutBrokeragePercent] =
    React.useState(0);
  const [brokerMcxBrokerage, setBrokerMcxBrokerage] = React.useState(0);
  const [brokerMcxBrokeragePercent, setBrokerMcxBrokeragePercent] =
    React.useState(0);
  const [brokerFoBrokerage, setBrokerFoBrokerage] = React.useState(0);
  const [brokerFoBrokeragePercent, setBrokerFoBrokeragePercent] =
    React.useState(0);

  const [brokerSharingPercent, setBrokerSharingPercent] = React.useState(0);

  const [brokerForexBrokerage, setBrokerForexBrokerage] = React.useState(0);
  const [brokerForexBrokeragePercent, setBrokerForexBrokeragePercent] =
    React.useState(0);

  const [brokerCryptoBrokerage, setBrokerCryptoBrokerage] = React.useState(0);
  const [brokerCryptoBrokeragePercent, setBrokerCryptoBrokeragePercent] =
    React.useState(0);

  const [brokerStocksBrokerage, setBrokerStocksBrokerage] = React.useState(0);
  const [brokerStocksBrokeragePercent, setBrokerStocksBrokeragePercent] =
    React.useState(0);

  const margin = JSON.parse(localStorage.getItem("adminsDetails")).margin;
  const [masterBrokearge, setMasterBrokearge] = React.useState([]);

  const [error, setError] = React.useState("");
  React.useEffect(() => {
    loadBrokers();
    load_master_brokerages();
  }, []);

  const load_master_brokerages = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
      };

      php.load_master_brokerages(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setMasterBrokearge(r.masters_brokerage);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const loadBrokers = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
      };
      php.load_brokers_admin(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setBrokerList(r.brokers);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const add_user = (e) => {
    if (name == "") {
      addNotification("Please Enter Name", "error");
    } else if (username == "" || username.length > 14 || username.length < 2) {
      addNotification("Please Enter Username Upto 14 Character", "error");
    } else if (password == "" || password.length < 3) {
      addNotification(
        "Please Enter Password with atleast 3 character",
        "error"
      );
    } else if (mobile == "" || mobile.length != 10) {
      setError("Mobile");
      addNotification("Please Enter Mobile Number with 10 Digit", "error");
    } else if (balance < 0) {
      addNotification("Please Enter Balance ", "error");
    } else if (futBrokerage < 0.01) {
      addNotification("Please Enter FUTURE Brokerage more than 0.02", "error");
    } else if (mcxBrokerage < 0.01 && mcxVolumeWise == "1") {
      addNotification("Please Enter FUTURE Brokerage more than 0.02", "error");
    } else if (mcxBrokerage < 20 && mcxVolumeWise == "0") {
      addNotification("Please Enter MCX Brokerage more than 20", "error");
    } else if (foBrokerage < 15) {
      addNotification("Please Enter OPTIONS Brokerage more than 15", "error");
    } else if (
      parseFloat(forexBrokerage) < masterBrokearge?.min_forex_brokerage ||
      parseFloat(forexBrokerage) > masterBrokearge?.max_forex_brokerage
    ) {
      setError("Forex Brokerage");
      addNotification(
        "Enter Min Forex Brokerage Between " +
          masterBrokearge?.min_forex_brokerage +
          " & " +
          masterBrokearge?.max_forex_brokerage,
        "error"
      );
    } else if (
      parseFloat(cryptoBrokerage) < masterBrokearge?.min_crypto_brokerage ||
      parseFloat(cryptoBrokerage) > masterBrokearge?.max_crypto_brokerage
    ) {
      setError("Crypto Brokerage");
      addNotification(
        "Enter Min Crypto Brokerage Between " +
          masterBrokearge?.min_crypto_brokerage +
          " & " +
          masterBrokearge?.max_crypto_brokerage,
        "error"
      );
    } else if (
      parseFloat(stocksBrokerage) < masterBrokearge?.min_stocks_brokerage ||
      parseFloat(stocksBrokerage) > masterBrokearge?.max_stocks_brokerage
    ) {
      setError("US Stocks Brokerage");
      addNotification(
        "Enter Min stocks Brokerage Between " +
          masterBrokearge?.min_stocks_brokerage +
          " & " +
          masterBrokearge?.max_stocks_brokerage,
        "error"
      );
    } else if (brokerId > 0 && brokerFutBrokeragePercent < 0) {
      addNotification(
        "Broker FUTURE Brokerage Must Be Greater Than 1",
        "error"
      );
    } else if (brokerId > 0 && brokerMcxBrokeragePercent < 0) {
      addNotification("Broker MCX Brokerage Must Be Greater Than 1", "error");
    } else if (brokerId > 0 && brokerFoBrokeragePercent < 0) {
      addNotification(
        "Broker OPTION Brokerage Must Be Greater Than 1",
        "error"
      );
    } else if (brokerId?.id > 0 && brokerForexBrokeragePercent < 0) {
      addNotification("Broker FOREX Brokerage Must Be Greater Than 1", "error");
    } else if (brokerId?.id > 0 && brokerCryptoBrokeragePercent < 0) {
      addNotification(
        "Broker CRYPTO Brokerage Must Be Greater Than 1",
        "error"
      );
    } else if (brokerId?.id > 0 && brokerStocksBrokeragePercent < 0) {
      addNotification(
        "Broker US Stocks Brokerage Must Be Greater Than 1",
        "error"
      );
    } else if (brokerId > 0 && brokerSharingPercent < 0) {
      addNotification("Broker SHARING Must Be Greater Than 1", "error");
    } else {
      if (!loading) {
        setLoading(false);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          a_id: localStorage.getItem("adminsId"),
          balance: balance,
          name: name,
          username: username,
          password: password,
          mobile: mobile,
          fut_brokerage: futBrokerage,
          mcx_brokerage: mcxBrokerage,
          fo_brokerage: foBrokerage,
          fo_brokerage: foBrokerage,
          mcx_volume_wise: mcxVolumeWise,
          forex_brokerage: parseFloat(forexBrokerage).toFixed(3),
          crypto_brokerage: parseFloat(cryptoBrokerage).toFixed(3),
          stocks_brokerage: parseFloat(stocksBrokerage).toFixed(3),
          ///broker
          broker_id: brokerId?.id,
          broker_fut_brokerage:
            brokerId?.id > 0 ? brokerFutBrokeragePercent : 0,
          broker_mcx_brokerage:
            brokerId?.id > 0 ? brokerMcxBrokeragePercent : 0,
          broker_fo_brokerage: brokerId?.id > 0 ? brokerFoBrokeragePercent : 0,
          broker_sharing: brokerId?.id > 0 ? brokerSharingPercent : 0,
          broker_forex_brokerage:
            brokerId?.id > 0 ? brokerForexBrokeragePercent : 0,
          broker_crypto_brokerage:
            brokerId?.id > 0 ? brokerCryptoBrokeragePercent : 0,
          broker_stocks_brokerage:
            brokerId?.id > 0 ? brokerStocksBrokeragePercent : 0,
        };

        php.add_user(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            addNotification(r.message, "success");
            props.reload();
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  function calcFutPercent(fut) {
    var result = (parseFloat(fut).toFixed(3) * 100) / parseFloat(futBrokerage);
    var roundedResult = result.toFixed(2);

    if (parseFloat(roundedResult) > 100 || isNaN(roundedResult)) {
      setBrokerFutBrokerage(0);
      setBrokerFutBrokeragePercent(0);
      addNotification("Broker Brokerage Must Between 0 To 100%", "error");
    } else {
      setBrokerFutBrokerage(fut);
      setBrokerFutBrokeragePercent(parseFloat(roundedResult));
    }
  }

  function calcMcxPercent(mcx) {
    var result = (parseFloat(mcx).toFixed(3) * 100) / parseFloat(mcxBrokerage);
    var roundedResult = result.toFixed(2);

    if (parseFloat(roundedResult) > 100 || isNaN(roundedResult)) {
      setBrokerMcxBrokerage(0);
      setBrokerMcxBrokeragePercent(0);
      addNotification("Broker Brokerage Must Between 0 To 100%", "error");
    } else {
      setBrokerMcxBrokerage(mcx);
      setBrokerMcxBrokeragePercent(parseFloat(roundedResult));
      console.log(roundedResult);
    }
  }

  function calcFoPercent(fo) {
    var result = (parseFloat(fo).toFixed(3) * 100) / parseFloat(foBrokerage);
    var roundedResult = result.toFixed(2);

    if (parseFloat(roundedResult) > 100 || isNaN(roundedResult)) {
      setBrokerFoBrokerage(0);
      setBrokerFoBrokeragePercent(0);
      addNotification("Broker Brokerage Must Between 0 To 100%", "error");
    } else {
      setBrokerFoBrokerage(fo);
      setBrokerFoBrokeragePercent(parseFloat(roundedResult));
    }
  }

  function calcForexPercent(forex) {
    var result = (parseFloat(forex) * 100) / parseFloat(forexBrokerage);
    var roundedResult = result.toFixed(3);

    if (
      parseFloat(roundedResult) > 100 ||
      parseFloat(roundedResult) < 0 ||
      isNaN(roundedResult)
    ) {
      setBrokerForexBrokerage("");
      setBrokerForexBrokeragePercent(0);
      addNotification("Broker Brokerage Must Between 0 To 100%", "error");
    } else {
      setBrokerForexBrokerage(forex);
      setBrokerForexBrokeragePercent(parseFloat(roundedResult));
    }
  }

  function calcCryptoPercent(crypto) {
    var result = (parseFloat(crypto) * 100) / parseFloat(cryptoBrokerage);
    var roundedResult = result.toFixed(3);

    if (
      parseFloat(roundedResult) > 100 ||
      parseFloat(roundedResult) < 0 ||
      isNaN(roundedResult)
    ) {
      setBrokerCryptoBrokerage("");
      setBrokerCryptoBrokeragePercent(0);
      addNotification("Broker Brokerage Must Between 0 To 100%", "error");
    } else {
      setBrokerCryptoBrokerage(crypto);
      setBrokerCryptoBrokeragePercent(parseFloat(roundedResult));
    }
  }

  function calcStocksPercent(stocks) {
    var result = (parseFloat(stocks) * 100) / parseFloat(stocksBrokerage);
    var roundedResult = result.toFixed(3);

    if (
      parseFloat(roundedResult) > 100 ||
      parseFloat(roundedResult) < 0 ||
      isNaN(roundedResult)
    ) {
      setBrokerStocksBrokerage("");
      setBrokerStocksBrokeragePercent(0);
      addNotification("Broker Brokerage Must Between 0 To 100%", "error");
    } else {
      setBrokerStocksBrokerage(stocks);
      setBrokerStocksBrokeragePercent(parseFloat(roundedResult));
    }
  }

  const calcSharing = (value) => {
    if (parseFloat(value) > parseFloat(margin) && margin != 0) {
      setBrokerSharingPercent("");
      alert("Sharing Must Be Between 0 To " + margin);
      // } else if (sharing < 1 && value > 0) {
      //   setSharing(0);
      //   alert.error("Sharing Must Be Between 0 To " + sharing);
    } else {
      setBrokerSharingPercent(value);
    }
  };

  const handleBrokerage = (value) => {
    if (parseFloat(value).toFixed(3) < 1) {
      setMcxVolumeWise("1");
    } else {
      setMcxVolumeWise("0");
    }
    setMcxBrokerage(value);
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        height: "100%",
        // padding: 10,
        boxSizing: "border-box",
      }}
    >
      {/* <Patti /> */}
      <Card
        title="Add Client"
        style={{
          margin: 10,
          position: "relative",
          height: "calc(100% - (60px - 1px))",
          overflowY: "scroll",
        }}
      >
        <CreateClientButton
          active={false}
          color={NewTheme.redcolor}
          onClick={() => props.close()}
          style={{ position: "absolute", top: 10, right: 5 }}
        >
          Close
        </CreateClientButton>
        <RightPanelContainer>
          <BalanceWrapper>
            <BalanceContainer>
              <BalanceLabel> Name </BalanceLabel>
              <BalanceInput
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Enter Name "
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Username </BalanceLabel>
              <BalanceInput
                type="text"
                placeholder="Enter Useranme "
                onChange={(e) => setUserame(e.target.value)}
                value={username}
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Password</BalanceLabel>
              <BalanceInput
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Mobile</BalanceLabel>
              <BalanceInput
                type="number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Enter Mobile"
                style={{
                  border: error == "Mobile" ? "1px solid red" : "",
                }}
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Balance</BalanceLabel>
              <BalanceInput
                type="number"
                value={balance}
                onChange={(e) => setBalance(e.target.value)}
                placeholder="Enter per Cr"
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Fut Brokerage</BalanceLabel>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%", fontSize: 12 }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>{"Min "}</label>
                  <label>{masterBrokearge?.min_fut_brokerage}</label>
                </span>
                <BalanceInput
                  type="number"
                  style={{
                    width: "100%",
                    border: error == "Future Brokerage" ? "1px solid red" : "",
                  }}
                  value={futBrokerage}
                  onChange={(e) => setFutBrokerage(e.target.value)}
                  placeholder="Enter Max Future Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label>{masterBrokearge?.max_fut_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>
                Mcx Brokerage
                <br />
                <span>( Cr wise - enter less than 1)</span>
              </BalanceLabel>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%", fontSize: 12 }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>{"Min "}</label>
                  <label>
                    {parseFloat(mcxBrokerage) < 1
                      ? masterBrokearge?.min_cr_mcx_brokerage
                      : masterBrokearge?.min_mcx_brokerage}
                  </label>
                </span>
                <BalanceInput
                  type="number"
                  style={{
                    width: "100%",
                    border: error == "Mcx Brokerage" ? "1px solid red" : "",
                  }}
                  value={mcxBrokerage}
                  onChange={(e) => handleBrokerage(e.target.value)}
                  placeholder="Enter Max Future Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label>
                    {parseFloat(mcxBrokerage) < 1
                      ? masterBrokearge?.max_cr_mcx_brokerage
                      : masterBrokearge?.max_mcx_brokerage}
                  </label>
                </span>
              </div>
            </BalanceContainer>{" "}
            <BalanceContainer>
              <BalanceLabel>Option Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%", fontSize: 12 }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>{"Min "}</label>
                  <label>{masterBrokearge?.min_fo_brokerage}</label>
                </span>
                <BalanceInput
                  type="number"
                  style={{
                    width: "100%",
                    // border:
                    //   error == "Max Future Brokerage" ? "1px solid red" : "",
                  }}
                  value={foBrokerage}
                  onChange={(e) => setFoBrokerage(e.target.value)}
                  placeholder="Enter Max Future Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label>{masterBrokearge?.max_fo_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Forex Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%", fontSize: 12 }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>{"Min "}</label>
                  <label>{masterBrokearge?.min_forex_brokerage}</label>
                </span>
                <BalanceInput
                  type="number"
                  style={{
                    width: "100%",
                    // border:
                    //   error == "Max Future Brokerage" ? "1px solid red" : "",
                  }}
                  value={forexBrokerage}
                  onChange={(e) => setForexBrokerage(e.target.value)}
                  placeholder="Enter Max Future Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label>{masterBrokearge?.max_forex_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Crypto Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%", fontSize: 12 }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>{"Min "}</label>
                  <label>{masterBrokearge?.min_crypto_brokerage}</label>
                </span>
                <BalanceInput
                  type="number"
                  style={{
                    width: "100%",
                    // border:
                    //   error == "Max Future Brokerage" ? "1px solid red" : "",
                  }}
                  value={cryptoBrokerage}
                  onChange={(e) => setCryptoBrokerage(e.target.value)}
                  placeholder="Enter Max Future Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label>{masterBrokearge?.max_crypto_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>US Stocks Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%", fontSize: 12 }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>{"Min "}</label>
                  <label>{masterBrokearge?.min_stocks_brokerage}</label>
                </span>
                <BalanceInput
                  type="number"
                  style={{
                    width: "100%",
                    // border:
                    //   error == "Max Future Brokerage" ? "1px solid red" : "",
                  }}
                  value={stocksBrokerage}
                  onChange={(e) => setStocksBrokerage(e.target.value)}
                  placeholder="Enter Max Future Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label>{masterBrokearge?.max_stocks_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Select Broker</BalanceLabel>
              <Dropdown
                style={{
                  width: "50%",
                  height: "40px",
                }}
                onChange={(e) => {
                  setBrokerId(e.value);
                  console.log(e.value);
                }}
                options={brokerList}
                value={brokerId}
                optionLabel="username"
                placeholder="Filter Username"
                filter
                showClear
              />
            </BalanceContainer>
            {brokerId?.id > 0 ? (
              <>
                <BalanceContainer>
                  <BalanceLabel>Broker Future Brokerage</BalanceLabel>
                  <div style={{ width: "50%" }}>
                    <BalanceInput
                      type="number"
                      value={brokerFutBrokerage}
                      onChange={(e) => calcFutPercent(e.target.value)}
                      placeholder="Enter per Qty"
                    />
                    <BalanceInput
                      value={brokerFutBrokeragePercent + "%"}
                      placeholder="0%"
                      disabled
                    />
                  </div>
                </BalanceContainer>
                <BalanceContainer>
                  <BalanceLabel>Broker Mcx Brokerage</BalanceLabel>
                  <div style={{ width: "50%" }}>
                    <BalanceInput
                      type="number"
                      value={brokerMcxBrokerage}
                      onChange={(e) => calcMcxPercent(e.target.value)}
                      placeholder="Enter per Lot"
                    />
                    <BalanceInput
                      value={brokerMcxBrokeragePercent + "%"}
                      placeholder="0%"
                      disabled
                    />
                  </div>
                </BalanceContainer>
                <BalanceContainer>
                  <BalanceLabel>Broker Option Brokerage</BalanceLabel>
                  <div style={{ width: "50%" }}>
                    <BalanceInput
                      type="number"
                      value={brokerFoBrokerage}
                      onChange={(e) => calcFoPercent(e.target.value)}
                      placeholder="Enter per Lot"
                    />
                    <BalanceInput
                      value={brokerFoBrokeragePercent + "%"}
                      placeholder="0%"
                      disabled
                    />
                  </div>
                </BalanceContainer>

                <BalanceContainer>
                  <BalanceLabel>Broker Forex Brokerage</BalanceLabel>
                  <div style={{ width: "50%" }}>
                    <BalanceInput
                      type="number"
                      value={brokerForexBrokerage}
                      onChange={(e) => calcForexPercent(e.target.value)}
                      placeholder="Enter per Lot"
                    />
                    <BalanceInput
                      value={brokerForexBrokeragePercent + "%"}
                      placeholder="0%"
                      disabled
                    />
                  </div>
                </BalanceContainer>

                <BalanceContainer>
                  <BalanceLabel>Broker Crypto Brokerage</BalanceLabel>
                  <div style={{ width: "50%" }}>
                    <BalanceInput
                      type="number"
                      value={brokerCryptoBrokerage}
                      onChange={(e) => calcCryptoPercent(e.target.value)}
                      placeholder="Enter per Lot"
                    />
                    <BalanceInput
                      value={brokerCryptoBrokeragePercent + "%"}
                      placeholder="0%"
                      disabled
                    />
                  </div>
                </BalanceContainer>

                <BalanceContainer>
                  <BalanceLabel>Broker US Stocks Brokerage</BalanceLabel>
                  <div style={{ width: "50%" }}>
                    <BalanceInput
                      type="number"
                      value={brokerStocksBrokerage}
                      onChange={(e) => calcStocksPercent(e.target.value)}
                      placeholder="Enter per Lot"
                    />
                    <BalanceInput
                      value={brokerStocksBrokeragePercent + "%"}
                      placeholder="0%"
                      disabled
                    />
                  </div>
                </BalanceContainer>

                <BalanceContainer>
                  <BalanceLabel>Broker Sharing</BalanceLabel>

                  <BalanceInput
                    type="number"
                    value={brokerSharingPercent}
                    onChange={(e) => calcSharing(e.target.value)}
                    placeholder="Enter Sharing"
                  />
                </BalanceContainer>
              </>
            ) : null}
          </BalanceWrapper>
          <BalanceUpdateButton onClick={add_user}>Save</BalanceUpdateButton>
        </RightPanelContainer>
      </Card>
    </div>
  );
}
