import { Button } from "primereact/button";
import React, { useState } from "react";
import Php from "../../../Backend/Php";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import {
  BlackText,
  BlueText,
  BoldText,
  ButtonGrid,
  CreateClientButton,
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
  Wrapper,
} from "../../Users/Overview/Styled";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import MultiplierModal from "../Modal/MultiplierModal";
import { ProgressSpinner } from "primereact/progressspinner";
import BrokerageModal from "../Modal/BrokerageModal";

export const FlexRow = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-start; // Adjust this as needed
  gap: 10px; // Adjust the gap between items as needed
  margin-bottom: 10px; // Adds some space between the rows
`;

export const BlueTextLocal = styled.span`
  color: ${({ color }) => color}; // Apply color dynamically
  flex-grow: 1;
  position: relative;
  flex-basis: calc(20% - 10px); // Occupies 20% of the row minus the gap
  text-align: left; // Ensure text within BlueText is left-aligned
  /* Ensure wrapping or any additional styles as needed */
`;

const php = new Php();

export default function Overview() {
  const [loading, setLoading] = React.useState(false);
  const [buttonsData, setButtonsData] = React.useState([]);
  const [clientData, setClientData] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_profile();
  }, []);

  const load_profile = () => {
    if (!loading) {
      setLoading(true);
      let data2 = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
      };

      php
        .load_profile(data2)
        .then((r) => {
          if (r.error == "False") {
            setClientData(r.profile[0]);
            setButtonsData(r.buttons_data);
          } else {
            addNotification(r.message, "error");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  function handleUsersMultiplier(fut, mcx, fo) {
    if (
      isNaN(parseFloat(fut)) ||
      isNaN(parseFloat(mcx)) ||
      isNaN(parseFloat(fo))
    ) {
      window.alert("Only Number Are Allowed");
    } else if (
      parseFloat(fut) <= 0 ||
      parseFloat(mcx) <= 0 ||
      parseFloat(fo) <= 0
    ) {
      window.alert("Enter Number Greater than 0");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          a_id: localStorage.getItem("adminsId"),
          c_id: clientData.id,
          fut_multiplier: fut,
          mcx_multiplier: mcx,
          fo_multiplier: fo,
        };

        php.change_users_multiplier(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            load_profile();
            setVisible(false);
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  }

  const [visible, setVisible] = useState(false);
  const [visibleBrokerage, setVisibleBrokerage] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setPosition("top");
    setVisible(true);
  };

  const showBrokerageModal = (position) => {
    setPosition("top");
    setVisibleBrokerage(true);
  };

  function chunkArray(array, size) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  }

  const Row = ({ label, value }) => (
    <div style={{ display: "flex" }}>
      <div style={{ width: "70px" }}>
        <BoldText>{label}</BoldText>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <FontAwesomeIcon
          color={NewTheme.MainColor}
          icon={faArrowAltCircleRight}
        />
      </div>
      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <BoldText>{parseFloat(value)}</BoldText>
      </div>
    </div>
  );

  return loading ? (
    <ProgressSpinner
      style={{ width: "50px", height: "50px" }}
      strokeWidth="8"
      fill="var(--surface-ground)"
      animationDuration=".5s"
    />
  ) : (
    <>
      <Wrapper>
        <FlexContainer>
          <FlexItem>
            <GrayText>Admin Name</GrayText>
            <BoldText>
              <span style={{ color: "gray" }}>
                {clientData?.alias == null || clientData?.alias == ""
                  ? ""
                  : clientData?.alias + " | "}
              </span>
              {clientData?.name}
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Admin Username</GrayText>
            <BlueText>{clientData?.username}</BlueText>
          </FlexItem>
          <FlexItem>
            <GrayText>Admin Server</GrayText>
            <BlueText>{clientData?.server}</BlueText>
          </FlexItem>
        </FlexContainer>

        <FlexContainer>
          <FlexItem>
            <GrayText>Masters</GrayText>
            <BlueText>{clientData?.masters}</BlueText>
          </FlexItem>
          <FlexItem>
            <GrayText>Users</GrayText>
            <BlueText>{clientData?.users}</BlueText>
          </FlexItem>
          <FlexItem>
            <GrayText>Brokers</GrayText>
            {clientData?.brokers}
          </FlexItem>
        </FlexContainer>

        <FlexContainer>
          <FlexItem>
            <GrayText>Default Users Settings</GrayText>
            {chunkArray(clientData?.setting_data || [], 5).map(
              (chunk, index) => (
                <FlexRow key={index}>
                  {chunk.map((button, buttonIndex) => (
                    <BlueTextLocal key={buttonIndex}>
                      <span
                        style={{
                          position: "absolute",
                          left: 0,
                          bottom: -2, // Adjust as needed to position the underline correctly
                          width: "100%",
                          height: 1,
                          backgroundColor: button.color,
                        }}
                      />
                      {/* <span
                        style={{
                          borderBottom: `1px solid ${button.color}`,
                          color: button.color,
                        }}
                      >
                        
                      </span> */}
                      {button.text}
                      <span
                        style={{
                          color: button.active == "1" ? "green" : "gray",
                          fontWeight: button.active == "1" ? "bold" : "normal",
                        }}
                      >
                        :{button.active == "1" ? "ON" : "OFF"}
                      </span>
                    </BlueTextLocal>
                  ))}
                </FlexRow>
              )
            )}
          </FlexItem>
        </FlexContainer>

        <FlexContainer>
          <FlexItem>
            <GrayText>
              Default Users Multiplier
              <Button
                label="Edit"
                outlined
                aria-label="Filter"
                size="small"
                style={{
                  padding: 0,
                  marginLeft: 5,
                  height: "20px",
                  fontSize: 12,
                  width: "40px",
                  color: "#ffffff",
                  backgroundColor: NewTheme.MainColor,
                }}
                onClick={() => show()}
              />
            </GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Row label="Fut" value={clientData?.fut_multiplier} />
                <Row label="Mcx" value={clientData?.mcx_multiplier} />
                <Row label="Option" value={clientData?.fo_multiplier} />
              </div>
              <div
                style={{
                  borderLeft: "1px solid gray",
                  paddingLeft: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row label="Forex" value={clientData?.forex_multiplier} />
                <Row label="US.Stocks" value={clientData?.stocks_multiplier} />
                <Row label="Crypto" value={clientData?.crypto_multiplier} />
              </div>
            </div>
          </FlexItem>
          <FlexItem>
            <GrayText>
              Default Users Brokerages
              <Button
                label="Edit"
                outlined
                aria-label="Filter"
                size="small"
                style={{
                  padding: 0,
                  marginLeft: 5,
                  height: "20px",
                  fontSize: 12,
                  width: "40px",
                  color: "#ffffff",
                  backgroundColor: NewTheme.MainColor,
                }}
                onClick={() => showBrokerageModal()}
              />
            </GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Row label="Fut" value={clientData?.fut_brokerage} />
                <Row label="Mcx" value={clientData?.mcx_brokerage} />
                <Row label="Option" value={clientData?.fo_brokerage} />
              </div>
              <div
                style={{
                  borderLeft: "1px solid gray",
                  paddingLeft: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row label="Forex" value={clientData?.forex_brokerage} />
                <Row label="US.Stocks" value={clientData?.stocks_brokerage} />
                <Row label="Crypto" value={clientData?.crypto_brokerage} />
              </div>
            </div>
          </FlexItem>
          <FlexItem>
            <GrayText>Default CF Margin</GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Row label="Fut" value={clientData?.fut_cf_margin} />
                <Row label="Mcx" value={clientData?.mcx_cf_margin} />
                <Row label="Option" value={clientData?.fo_cf_margin} />
              </div>
              <div
                style={{
                  borderLeft: "1px solid gray",
                  paddingLeft: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row label="Forex" value={clientData?.forex_cf_margin} />
                <Row label="US.Stocks" value={clientData?.stocks_cf_margin} />
                <Row label="Crypto" value={clientData?.crypto_cf_margin} />
              </div>
            </div>
          </FlexItem>
        </FlexContainer>
      </Wrapper>

      <ButtonGrid>
        {/* <CreateClientButton
          active={true}
          color={NewTheme.MainColor}
          onClick={() => showLiveSummaryModal()}
        >
          {"LIVE SUMMARY"}
        </CreateClientButton> */}
        <CreateClientButton
          active={clientData?.status == 1 ? true : false}
          color={"#22c55e"}
          //   onClick={() => handleStatusButtonClick()}
        >
          {"ACTIVE"}
        </CreateClientButton>
        {buttonsData.map((button, index) => (
          <CreateClientButton
            key={index}
            active={button.active}
            color={button.color}
            // onClick={() => handleButtonClick(button.id)} // Add the onClick handler
          >
            {button.text}
          </CreateClientButton>
        ))}
      </ButtonGrid>

      {/* {visibleDepositModal ? (
        <DepositModal
          key={clientData?.id}
          setVisible={() => setVisibleDepositModal(false)}
          position={position}
          id={clientData?.id}
          visible={visibleDepositModal}
          reload={() => load_profile()}
        />
      ) : null}

      {visibleLimitModal ? (
        <LimitModal
          key={clientData?.id}
          setVisible={() => setVisibleLimitModal(false)}
          position={position}
          id={clientData?.id}
          visible={visibleLimitModal}
          reload={() => load_profile()}
        />
      ) : null} */}

      {visible ? (
        <MultiplierModal
          key={clientData?.id}
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          fut={clientData?.fut_multiplier}
          mcx={clientData?.mcx_multiplier}
          fo={clientData?.fo_multiplier}
          forex={clientData?.forex_multiplier}
          crypto={clientData?.crypto_multiplier}
          stocks={clientData?.stocks_multiplier}
          reload={() => {
            setVisible(false);
            load_profile();
          }}
        />
      ) : null}

      {visibleBrokerage ? (
        <BrokerageModal
          key={clientData?.id}
          setVisible={() => setVisibleBrokerage(false)}
          position={position}
          visible={visibleBrokerage}
          fut={clientData?.fut_brokerage}
          mcx={clientData?.mcx_brokerage}
          fo={clientData?.fo_brokerage}
          forex={clientData?.forex_brokerage}
          crypto={clientData?.crypto_brokerage}
          stocks={clientData?.stocks_brokerage}
          reload={() => {
            setVisibleBrokerage(false);
            load_profile();
          }}
        />
      ) : null}

      {/* {visibleLiveSummaryModal ? (
        <LiveSummaryModal
          key={clientData?.id}
          usersId={clientData?.id}
          setVisible={() => setVisibleLiveSummaryModal(false)}
          position={position}
          visible={visibleLiveSummaryModal}
          //
          fut_multiplier={clientData?.fut_multiplier}
          mcx_multiplier={clientData?.mcx_multiplier}
          fo_multiplier={clientData?.fo_multiplier}
          //
          deposit={clientData?.deposit}
          blocked_limit={clientData?.blocked_limit}
          weekly_pl={clientData?.weekly_pl}
          clientUsername={clientData?.username}
        />
      ) : null} */}
    </>
  );
}
