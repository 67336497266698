// SoundUtility.js
import { Howl } from "howler";
import pendingaudio from "./pending.mp3";
import surveyaudio from "./survey.mp3";
import fsqaudio from "./fsqoff.mp3";
import booundryaudio from "./boundry.mp3";

import successAudio from "./noti.mp3";

export const pendningplaySound = () => {
  var pendingsound = new Howl({
    src: [pendingaudio],
    volume: 1.0,
  });
  pendingsound.play();
};

export const surveyPlaysound = () => {
  var surveysound = new Howl({
    src: [surveyaudio],
    volume: 1.0,
  });
  surveysound.play();
};

export const boundryPlaysound = () => {
  var boundsound = new Howl({
    src: [booundryaudio],
    volume: 1.0,
  });
  boundsound.play();
};

export const fsqoffPlaysounnd = () => {
  var fsqoffsound = new Howl({
    src: [fsqaudio],
    volume: 1.0,
  });

  fsqoffsound.play();
};
