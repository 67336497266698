import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";

const php = new Php();
const height = window.innerHeight;

export default function ClientsList(props) {
  const [loading, setLoading] = React.useState(false);
  const [clientsList, setClientsList] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_brokers_clients();
  }, []);

  const load_brokers_clients = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        b_id: props.data.id,
      };

      php.load_brokers_clients(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setClientsList(r.client_logs);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  return (
    <div className="card">
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={clientsList}
        size="small"
      >
        <Column
          style={{ width: "30%" }}
          showFilterMenu={false}
          field="name"
          header="Name"
        ></Column>
        <Column
          style={{ width: "30%" }}
          sortable
          showFilterMenu={false}
          field="username"
          header="Username"
        ></Column>
        <Column
          style={{ width: "40%" }}
          sortable
          showFilterMenu={false}
          field="deposit"
          header="Deposit"
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.deposit >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {rowData.deposit}
            </div>
          )}
        ></Column>
      </DataTable>
    </div>
  );
}
