import React, { useState } from "react";
import styled from "styled-components";
import { NewTheme } from "../Theme/Theme";
import Overview from "./Overview/Overview";
import Payment from "./Payment";
import Edit from "./Edit/Edit";
import LoginLogs from "./LoginLogs/LoginLogs";

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black;
`;

const Tabs = styled.div`
  display: flex;
  background-color: white;
  // border-top: 1px solid gray;
  // border-left: 1px solid gray;
  border-bottom: 1px solid gray;
  // border-right: 1px solid gray;
`;

const Tab = styled.div`
  padding: 15px 30px;
  color: black;
  cursor: pointer;
  border-right: 1px solid gray;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};

  ${(props) =>
    props.active &&
    `
    background-color: ${NewTheme.lightmain};
    color: ${NewTheme.MainColor};
    border-color: ${NewTheme.MainColor};
    border-bottom: 3px solid ${NewTheme.MainColor}; 
  `}
`;

const TabContent = styled.div`
  padding: 10px;
`;

function RightSide(props) {
  const [activeTab, setActiveTab] = useState("Overview"); // Set the initial active tab

  props.openRef.current = () => {
    setActiveTab("Overview");
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <RightPanelContainer>
      <Tabs>
        <Tab
          active={activeTab === "Overview"}
          onClick={() => handleTabClick("Overview")}
        >
          Overview
        </Tab>
        <Tab
          active={activeTab === "Payment"}
          onClick={() => handleTabClick("Payment")}
        >
          Payment
        </Tab>
        <Tab
          active={activeTab === "Edit"}
          onClick={() => handleTabClick("Edit")}
        >
          Edit
        </Tab>
        <Tab
          active={activeTab === "History"}
          onClick={() => handleTabClick("History")}
        >
          Login Logs
        </Tab>
        {/* <Tab
          active={activeTab === "Scrips"}
          onClick={() => handleTabClick("Scrips")}
        >
          Scripts
        </Tab> */}
      </Tabs>
      <TabContent>
        {/* Render content based on the active tab */}
        {activeTab === "Overview" && <Overview reload={() => props.reload()} />}
        {activeTab === "Payment" && <Payment reload={() => props.reload()} />}
        {activeTab === "Edit" && <Edit reload={() => props.reload()} />}
        {activeTab === "History" && <LoginLogs />}
        {/*  {activeTab === "Ledger" && <LedgerLogs data={props.data} />}
       
        {activeTab === "Rejection" && <RejectionLogs data={props.data} />}
        {activeTab === "Scrips" && <Script data={props.data} />}
        {activeTab === "Locks" && <Locks data={props.data} />}
        {activeTab === "Boundary" && <Fatak data={props.data} />} */}
      </TabContent>
    </RightPanelContainer>
  );
}

export default RightSide;
