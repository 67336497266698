import { Dialog } from "primereact/dialog";
import React from "react";
import { NewTheme } from "../../Theme/Theme";
import { Button } from "primereact/button";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
const php = new Php();
const height = window.innerHeight;

export default function DeleteModal(props) {
  const data = props.transactionDeleteData;
  const [loading, setLoading] = React.useState(false);

  const delete_transaction = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        t_id: props.transactionDeleteData.id,
      };

      php.delete_transaction(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          props.load_transactions_history();
          props.setVisible(false);
          window.alert(r?.message);
        } else {
          window.alert(r?.message);
        }
      });
    }
  };

  const reverse_transaction = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        t_id: props.transactionDeleteData.id,
      };

      php.reverse_transaction(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          props.load_transactions_history();
          props.setVisible(false);
          window.alert(r?.message);
        } else {
          window.alert(r?.message);
        }
      });
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Reverse Position"
        icon="pi pi-history"
        onClick={() => reverse_transaction()}
        style={{
          backgroundColor: NewTheme.MainColor,
        }}
      />
      <Button
        label="Only Delete"
        icon="pi pi-times"
        onClick={() => delete_transaction()}
        style={{
          backgroundColor: NewTheme.redcolor,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: NewTheme.redcolor,
        }}
      />
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div>
          {"Are You Sure Want To Delete Transaction Of"}{" "}
          <span
            style={{
              color: NewTheme.redcolor,
              fontSize: 20,
              textDecoration: "underline",
            }}
          >
            {" "}
            {data?.symbol}
          </span>{" "}
          {" In Client "}
          <span
            style={{
              color: NewTheme.MainColor,
              fontSize: 20,
              textDecoration: "underline",
            }}
          >
            {data?.username?.toUpperCase()}
          </span>
          {" With P/L "}
          <span
            style={{
              color:
                data?.profit_loss >= 0
                  ? NewTheme.greencolor
                  : NewTheme.redcolor,
              fontSize: 20,
              textDecoration: "underline",
            }}
          >
            {data?.profit_loss}
          </span>
          {" ?"}
        </div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "100vw" }}
      breakpoints={{ "960px": "95vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    ></Dialog>
  );
}
