import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { NewTheme } from "../../Theme/Theme";
import NumberFormatIn from "../../Common/NumberFormatIn";

const VolumeCard = forwardRef((props, ref) => {
  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 40,
          flexDirection: "column",
          minWidth: 90,
        }}
      >
        <div style={{ fontSize: 12 }}>M2M</div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: props.total3 >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
          }}
        >
          <NumberFormatIn value={parseFloat(props.total3).toFixed(2)} />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 40,
          flexDirection: "column",
          minWidth: 90,
        }}
      >
        <div style={{ fontSize: 12 }}>Buy volume</div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 18,
          }}
        >
          <NumberFormatIn value={parseFloat(props.buyVolume).toFixed(0)} />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 40,
          flexDirection: "column",
          minWidth: 90,
        }}
      >
        <div style={{ fontSize: 12 }}>Sell volume</div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 18,
          }}
        >
          <NumberFormatIn value={parseFloat(props.sellVolume).toFixed(0)} />
        </div>
      </div>
    </>
  );
});

const mapstatetoProps = (state) => {
  return {
    total3: state.total3,
  };
};
export default connect(mapstatetoProps)(VolumeCard);
