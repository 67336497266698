import React from "react";
import { NewTheme } from "../Theme/Theme";

export default function Marquee(props) {
  return props.marquee?.length > 0 ? (
    <marquee scrollamount={5}>
      <div style={{ display: "flex", height: 20 }}>
        {props.marquee?.map((i, t) => (
          <div style={{ color: "black", marginLeft: 20 }}>
            {t + 1}.
            <span style={{ color: NewTheme.MainColor, fontWeight: "bold" }}>
              {i.news_type}
            </span>{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>{i.news}</span>
          </div>
        ))}
      </div>
    </marquee>
  ) : null;
}
