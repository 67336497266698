import React, { useEffect } from "react";
import { useNotification } from "./NotificationContext";
import "./Notification.css";

const Notification = () => {
  const { notifications, removeNotification } = useNotification();

  useEffect(() => {
    // Check if there is a notification to display
    if (notifications.length > 0) {
      const notification = notifications[0];

      // Remove previous notifications, if any
      if (notifications.length > 1) {
        notifications.slice(1).forEach((n) => removeNotification(n.id));
      }

      // Set a timer to remove the current notification after 2 seconds
      const timer = setTimeout(() => {
        removeNotification(notification.id);
      }, 4000);

      // Clear the timer if the component unmounts or a new notification arrives
      return () => clearTimeout(timer);
    }
  }, [notifications, removeNotification]);

  return (
    <div className="notifications-container">
      {notifications.map((notification) => (
        <div
          key={notification.id}
          onClick={() => removeNotification(notification.id)}
          className={`notification ${notification.type}`}
        >
          <p>{notification.message}</p>
          <audio controls>
            <source src={notification.soundFile} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      ))}
    </div>
  );
};

export default Notification;
