import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";

import Php from "../../Backend/Php";
import "./Script.css";
import { NewTheme } from "../Theme/Theme";
import { useNotification } from "../Notification/NotificationContext";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { CreateClientButton } from "../Users/Overview/Styled";

const php = new Php();

function ScriptSearch(props) {
  const focusSearch = useRef();
  const focusAdd = useRef();
  const { addNotification } = useNotification();

  const [loading, setLoading] = React.useState(false);
  const [searchText, setScriptText] = useState("");
  const [searchScript, setSearchScript] = useState("");
  const [script, setScript] = useState([]);

  const find_script = (a) => {
    setSearchScript(a);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      a_id: localStorage.getItem("adminsId"),
      symbol: a,
    };

    php.search_script(data).then((r) => {
      if (r.error == "False") {
        setScript(r.scripts);
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const add_script = (a) => {
    // if (!loading) {
    //   setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      a_id: localStorage.getItem("adminsId"),
      script: a.symbol,
      script_type: a.script_type,
    };

    php.add_script(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        props.loadWatchlist();
        setScriptText("");
        setScript([]);
        setSearchScript("");
      } else {
        addNotification(r.message, "error");
      }
    });
    // }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 45,
        gap: 20,
      }}
    >
      {/* <CreateClientButton
    style={{ height: 30 }}
    active={true}
    color={NewTheme.MainColor}
    onClick={() => props.get_all_fut_scripts()}
  >
    {"All Fut"}
  </CreateClientButton> */}
      <CreateClientButton
        style={{ height: 30 }}
        active={true}
        color={NewTheme.MainColor}
        onClick={() => props.get_four_segment()}
      >
        {"International"}
      </CreateClientButton>
      <CreateClientButton
        style={{ height: 30 }}
        active={true}
        color={NewTheme.MainColor}
        onClick={() => props.get_all_fut_scripts()}
      >
        {"All Fut"}
      </CreateClientButton>
      <CreateClientButton
        style={{ height: 30 }}
        active={true}
        color={NewTheme.MainColor}
        onClick={() => props.get_all_mcx_scripts()}
      >
        {"All Mcx"}
      </CreateClientButton>
      <CreateClientButton
        style={{ height: 30 }}
        active={true}
        color={NewTheme.MainColor}
        onClick={() => props.toWatchlist()}
      >
        {"Watchlist"}
      </CreateClientButton>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <InputText
          ref={focusSearch}
          className={"logininput_dark"}
          value={searchText}
          onChange={(e) => {
            setScriptText(e.target.value);
            props.search_script(e.target.value);
          }}
          style={{
            color: NewTheme.MainColor,
            transition: "all .2s",
            borderRadius: 5,
            width: "90%",
          }}
          placeholder="Search Script"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <InputText
          ref={focusAdd}
          className={"logininput_dark"}
          value={searchScript}
          onChange={(e) => find_script(e.target.value)}
          style={{
            color: NewTheme.MainColor,
            transition: "all .2s",
            borderRadius: 5,
            width: "90%",
          }}
          placeholder="Add Script"
        />
        {searchScript.length > 0 ? (
          <div
            style={{
              position: "absolute",
              top: 40,
              zIndex: 99,
              //   height: 60,
              maxHeight: 500,
              width: 300,
              right: 10,
              borderRadius: 10,
              overflowY: "scroll",
              backgroundColor: NewTheme.MainColor,
              color: NewTheme.MainColor,
              transition: "all .2s",
            }}
          >
            {script.length > 0 ? (
              script.map((i) => {
                return (
                  <div
                    onClick={() => add_script(i)}
                    style={{
                      color: NewTheme.MainColor,
                      height: "30px",
                      backgroundColor: "#f5f5fc",
                      margin: 10,
                      borderRadius: 3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      transition: "all .2s",
                    }}
                  >
                    {i.symbol}
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    color: NewTheme.lightmain,
                    fontWeight: "bold",
                  }}
                >
                  Nothing here
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ScriptSearch;
