import React from "react";
import TopbarMost from "../Common/TopbarMost";
import styled from "styled-components";
import RightSide from "./RightSide";

const DashboardContainer = styled.div`
  display: flex;
  overflow-y: scroll;
  height: calc(
    100vh - 60px
  ); /* Subtract the top menu bar height from the viewport height */
`;

const RightPanel = styled.div`
  flex: 3; /* Takes up 3/4 of the width */
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-height: 100%; /* Set the maximum height to 100% of the adjusted viewport height */
`;

export default function Profile() {
  const openRef = React.useRef();
  return (
    <>
      <TopbarMost name={"Profile"} />
      <DashboardContainer>
        <RightPanel>
          <RightSide openRef={openRef} />
        </RightPanel>
      </DashboardContainer>
    </>
  );
}
