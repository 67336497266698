import React from "react";
import styled from "styled-components";
import { NewTheme } from "../../Theme/Theme";
import moment from "moment";

const ClientCard = styled.div`
  border-bottom: 1px solid lightgray;
  border-right: none;
  background-color: ${(props) =>
    props.status == 0
      ? NewTheme.lightred
      : props.selected
      ? NewTheme.lightmain
      : "rgb(255, 255, 255)"};
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
`;

const LeftDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
`;

const RightDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const UsernameNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Username = styled.div`
  font-size: 20px;
  font-weight: bolder;
  color: rgb(74, 107, 161);
  text-align: left; /* Set text alignment to left */
  color: ${NewTheme.MainColor};
`;

const Name = styled.div`
  font-size: 14px;
  color: gray;
  font-weight: bolder;
  margin-top: 3px;
`;

const Status = styled.div`
  font-size: 14px;
  color: gray;
  font-weight: bolder;
  text-align: left; /* Set text alignment to left */
  margin-top: 3px;
`;

const TotalDeposit = styled.div`
  font-size: 14px;
  color: gray;
  font-weight: bolder;
  text-align: left; /* Set text alignment to left */
`;

function MastersList(props) {
  const { clients } = props;
  return (
    <div>
      {clients.map((client) => (
        <>
          <ClientCard
            onClick={() => props.select(client)}
            selected={props.selected.id == client.id}
            key={client.id}
            status={client.status}
          >
            <LeftDetails>
              <UsernameNameContainer>
                <Username>{client.username}</Username>
                <Name>
                  {client.alias == null || client.alias == ""
                    ? ""
                    : client.alias + " | "}
                  {client.name}
                </Name>
                {/* <TotalDeposit> {"Broker Name"}</TotalDeposit> */}
              </UsernameNameContainer>
            </LeftDetails>
            <RightDetails>
              <div>
                <TotalDeposit>Magin : {client.margin}</TotalDeposit>
                {/* <TotalDeposit>T : {client.total_limit}</TotalDeposit> */}
                <Status>
                  {client.last_active_status == 0 ? (
                    <span style={{ color: "#22c55e" }}> Online</span>
                  ) : client.last_active_status != null ? (
                    moment(client.last_active_status).fromNow()
                  ) : null}
                </Status>
                {/* <TotalDeposit>M : {client.id}</TotalDeposit> */}
              </div>
            </RightDetails>
          </ClientCard>
        </>
      ))}
    </div>
  );
}

export default MastersList;
