import * as ExcelJS from "exceljs";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import TopbarMost from "../../Common/TopbarMost";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import jsPDF from "jspdf";
import "jspdf-autotable";
import NumberFormatIn from "../../Common/NumberFormatIn";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";

const php = new Php();
const height = window.innerHeight;

export default function Summary() {
  const [loading, setLoading] = React.useState(false);
  const [summary, setSummary] = React.useState([]);
  const { addNotification } = useNotification();

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [filters, setFilters] = React.useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  React.useEffect(() => {
    load_users_summary();
  }, []);

  const load_users_summary = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
      };

      php.load_users_summary_new(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setSummary(r.users_summary);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the actual columns you want to export
    const colsToExport = [
      "master",
      "name",
      "username",
      "alias",
      "mobile",
      "closing_balance",
      "pl",
      "deposit",
      "total_deposit",
      "last_deposit",
      "total_withdraw",
      "last_withdraw",
      "brokers",
      "date_created",
    ];

    // Specify the display names for the headers
    const headerDisplayNames = [
      "Master",
      "Client",
      "Username",
      "Alias",
      "Mobile",
      "Opening Balance",
      "Total P/L",
      "Current Balance",
      "Total Deposit",
      "Last Deposit Date",
      "Total Withdraw",
      "Last Withdraw Date",
      "Broker",
      "Date Created",
    ];

    const headerRow = worksheet
      .addRow([
        "CLIENTS SUMMARY (S)" +
          localStorage.getItem("server").toUpperCase() +
          " (A)" +
          localStorage.getItem("adminsUsername")?.toUpperCase(),
      ])
      .commit();
    worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
    const mergedRange = worksheet.getCell(
      `A${worksheet.rowCount}:E${worksheet.rowCount}`
    );
    mergedRange.alignment = { horizontal: "center" };
    mergedRange.font = { bold: true, size: 16 };

    // Add empty rows between summary and data
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    const headerDisplayNamesRow = worksheet.addRow(headerDisplayNames);

    // Make the header row bold
    headerDisplayNamesRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Extract only the desired columns from transactionData
    const filteredData = summary.map((item) => {
      return colsToExport.reduce((acc, col) => {
        acc[col] = item[col];
        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Set column width to 16 for all columns
    worksheet.columns.forEach((column) => {
      column.width = 16;
    });

    // Apply color to font based on the "profit_loss" condition
    const colIndex = colsToExport.indexOf("pl");
    const depositIndex = colsToExport.indexOf("deposit");
    const closingBalIndex = colsToExport.indexOf("closing_balance");
    const usernameIndex = colsToExport.indexOf("username"); // Add this line

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      const plValue = row.getCell(colIndex + 1).value; // "pl" column value
      const depositValue = row.getCell(depositIndex + 1).value; // "deposit" column value
      const closingBalValue = row.getCell(closingBalIndex + 1).value; // "deposit" column value
      const usernameValue = row.getCell(usernameIndex + 1).value; // "username" column value

      // Format for "profit_loss" column
      if (plValue !== null && rowNumber > 4) {
        const plColor =
          plValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative
        row.getCell(colIndex + 1).font = {
          color: { argb: plColor },
          bold: true, // Make the font bold
        };

        // Convert "profit_loss" to number and set number format
        row.getCell(colIndex + 1).value =
          rowNumber > 4 ? Number(plValue) : plValue;
        // row.getCell(colIndex + 1).numFmt =
        //   rowNumber > 4 ? "#,##0.00" : undefined; // Adjust the number format as needed
      }

      // Format for "deposit" column
      if (depositValue !== null && rowNumber > 4) {
        const depositColor =
          depositValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative
        row.getCell(depositIndex + 1).font = {
          color: { argb: depositColor },
          bold: true, // Make the font bold
        };

        // Convert "deposit" to number and set number format
        row.getCell(depositIndex + 1).value =
          rowNumber > 4 ? Number(depositValue) : depositValue;
        // row.getCell(depositIndex + 1).numFmt =
        //   rowNumber > 4 ? "#,##0.00" : undefined; // Adjust the number format as needed
      }

      // Format for "deposit" column
      if (closingBalValue !== null && rowNumber > 4) {
        const closingBalColor =
          closingBalValue > 0
            ? NewTheme.greencolorExcel
            : NewTheme.redcolorExcel; // Green for positive, Red for negative
        row.getCell(closingBalIndex + 1).font = {
          color: { argb: closingBalColor },
          bold: true, // Make the font bold
        };

        row.getCell(closingBalIndex + 1).value =
          rowNumber > 4 ? Number(closingBalValue) : closingBalValue;
      }

      // Format for "username" column
      if (usernameValue !== null && rowNumber > 4) {
        row.getCell(usernameIndex + 1).font = {
          color: { argb: NewTheme.MainColorExcel }, // Set the font color for the "username" column
          bold: true, // Make the font bold
        };
      }
    });

    var fileName =
      "CLIENTS SUMMARY (S)" +
      localStorage.getItem("server").toUpperCase() +
      " (A)" +
      localStorage.getItem("adminsUsername")?.toUpperCase();

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const cols = [
    { dataKey: "master", title: "Master" },
    { dataKey: "name", title: "Name" },
    { dataKey: "username", title: "Username" },
    { dataKey: "alias", title: "Alias" },
    { dataKey: "mobile", title: "Mobile" },
    { dataKey: "closing_balance", title: "Opening Balance" },
    { dataKey: "pl", title: "Total P/L" },
    { dataKey: "total_deposit", title: "Total Deposit" },
    { dataKey: "last_deposit", title: "Last Deposit Dt" },
    { dataKey: "total_withdraw", title: "Total Withdraw" },
    { dataKey: "last_withdraw", title: "Last Withdraw Dt" },
    { dataKey: "brokers", title: "Brokers" },
    { dataKey: "date_created", title: "Date Created" },
  ];

  const exportPdf = () => {
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });

    doc.setFontSize(16);
    doc.text("CLIENTS SUMMARY", 15, 15);

    doc.setFontSize(12);
    doc.text(`Server: ${localStorage.getItem("server").toUpperCase()}`, 15, 25);

    doc.setFontSize(12);
    doc.text(
      `Admin: ${localStorage.getItem("adminsUsername").toUpperCase()}`,
      75,
      25
    );

    const headerStyles = {
      fillColor: "#4a6ba1",
      textColor: "#ffffff",
    };

    // Add content to the PDF using autoTable with modified data and custom header styles
    const tableStartY = 35; // Adjust the startY value based on the header size and layout
    doc.autoTable(cols, summary, { startY: tableStartY, headerStyles });

    var pdfName =
      "CLIENTS SUMMARY (S)" +
      localStorage.getItem("server").toUpperCase() +
      " (A)" +
      localStorage.getItem("adminsUsername")?.toUpperCase();

    doc.save(pdfName + ".pdf");
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
        }}
      >
        <InputText
          style={{ width: 140, height: 30 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="PDF"
          severity="warning"
          style={{
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="XLS"
          severity="primary"
          style={{
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={exportExcel}
          data-pr-tooltip="EXCEL"
        />
      </div>
    </div>
  );

  return (
    <div className="card" style={{ backgroundColor: "#ffffff" }}>
      <TopbarMost
        height={30}
        onlyHeader
        cmp={filterbar}
        name={"CLIENTS SUMMARY"}
      />
      <DataTable
        removableSort
        stripedRows
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        showGridlines
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        scrollHeight={height - 200}
        scrollable
        paginator
        rows={15}
        rowsPerPageOptions={[10, 15, 30, 50, 100]}
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
        value={summary}
        loading={loading}
        globalFilterFields={["username", "master", "alias", "name", "brokers"]}
        size="small"
      >
        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="master"
          header="Master"
          sortable
        ></Column>
        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="name"
          header="Name"
          sortable
          body={(rowData) => (
            <>
              {rowData.name}
              <div
                style={{
                  fontSize: 10,
                  color: NewTheme.MainColor,
                  fontWeight: "bold",
                }}
              >
                {rowData.alias}
              </div>
            </>
          )}
        ></Column>
        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="username"
          header="Username"
          sortable
          body={(rowData) => (
            <>
              {rowData.username}
              <div
                style={{
                  fontSize: 10,
                  color: NewTheme.MainColor,
                  fontWeight: "bold",
                }}
              >
                {localStorage.getItem("adminsId") != "3"
                  ? null
                  : rowData.mobile == "" ||
                    rowData.mobile == "null" ||
                    rowData.mobile == null
                  ? "---"
                  : rowData.mobile}
              </div>
            </>
          )}
        ></Column>
        <Column
          style={{
            width: "10%",
          }}
          sortable
          showFilterMenu={false}
          field="closing_balance"
          body={(rowData) => (
            <span
              style={{
                color:
                  rowData.closing_balance >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={rowData.closing_balance} />
            </span>
          )}
          header="Closing Bal"
        ></Column>
        <Column
          style={{
            width: "10%",
          }}
          sortable
          showFilterMenu={false}
          field="pl"
          body={(rowData) => (
            <span
              style={{
                color:
                  rowData.pl >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={rowData.pl} />
            </span>
          )}
          header="Total P/L"
        ></Column>

        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="deposit"
          header="Balance"
          body={(rowData) => (
            <span
              style={{
                color:
                  rowData.deposit >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={rowData.deposit} />
            </span>
          )}
        ></Column>
        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="deposit"
          header="Total Deposit"
          body={(rowData) => (
            <span
              style={{
                color: NewTheme.greencolor,
              }}
            >
              <NumberFormatIn value={rowData.total_deposit} />
              <div style={{ fontSize: 12, color: "black" }}>
                {rowData.last_deposit == ""
                  ? "No Date"
                  : moment(rowData.last_deposit).format("DD-MMM-YY")}
              </div>
            </span>
          )}
        />
        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="deposit"
          header="Total Withdraw"
          body={(rowData) => (
            <span
              style={{
                color: NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={rowData.total_withdraw} />
              <div style={{ fontSize: 12, color: "black" }}>
                {rowData.last_withdraw == ""
                  ? "No Date"
                  : moment(rowData.last_withdraw).format("DD-MMM-YY")}
              </div>
            </span>
          )}
        />
        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="brokers"
          header="Broker"
        />
        <Column
          field="date_created"
          style={{ wordWrap: "break-word" }}
          showFilterMenu={false}
          header="Date/Time"
          body={(rowData) => (
            <div style={{ fontSize: 14 }}>
              {moment(rowData.date_created).format("DD-MMM-YY")}
            </div>
          )}
          sortable
        />
      </DataTable>
    </div>
  );
}
