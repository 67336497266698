import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { NewTheme } from "../../Theme/Theme";
import moment from "moment";
import UpdateTrade from "../../Common/Update";
import NumberFormatIn from "../../Common/NumberFormatIn";

const php = new Php();

export default function LiveTrade(props) {
  const [liveTradeData, setLiveTradeData] = React.useState([]);

  React.useEffect(() => {
    loadLiveTradeData();
  }, []);

  const rowClassName = (rowData) => {
    // Customize the logic to determine the background color based on rowData
    return rowData.short == 1 ? "highlighted-row" : "";
  };

  const loadLiveTradeData = () => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      a_id: localStorage.getItem("adminsId"),
    };
    php.load_live_transaction(data).then((r) => {
      if (r.error == "False") {
        setLiveTradeData(r.transactions);
      } else {
        // alert(r.message);
      }
    });
  };
  return (
    <>
      <UpdateTrade update={() => loadLiveTradeData()} />
      <DataTable
        stripedRows
        showGridlines
        scrollable
        id="HOMETABLE"
        value={liveTradeData}
        scrollHeight={props.height}
        rowClassName={rowClassName}
        size="small"
        style={{ fontWeight: "bold", fontSize: 14 }}
      >
        {/* <Column
          field="super_username"
          showFilterMenu={false}
          header="Super"
          body={(rowData) => (
            <>
              <div
                style={{
                  color: NewTheme.MainColor,
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {rowData.super_username}
              </div>
            </>
          )}
        /> */}
        <Column
          field="master_username"
          showFilterMenu={false}
          header="Master"
          body={(rowData) => (
            <>
              <div
                style={{
                  color: NewTheme.MainColor,
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {rowData.master_username}
              </div>
            </>
          )}
        />
        <Column
          field="username"
          showFilterMenu={false}
          header="Client"
          body={(rowData) => (
            <>
              <div
                style={{
                  color: NewTheme.MainColor,
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {rowData.username}
                <span style={{ fontSize: 10 }}>
                  {rowData.short == "1"
                    ? "  " + rowData.short_diff + " Min"
                    : ""}
                </span>
              </div>
            </>
          )}
        />
        <Column
          field="symbol"
          showFilterMenu={false}
          header="Symbol"
          body={(rowData) => (
            <>
              <div style={{ color: NewTheme.MainColor }}>{rowData.symbol}</div>
              <div
                style={{
                  marginTop: 0,
                  paddingTop: 0,
                  color: "gray",
                  fontWeight: "bold",
                  fontSize: 10,
                  height: "100%",
                }}
              >
                {moment(rowData.expiry_date).format("DD-MMM-YY")}
              </div>
            </>
          )}
        />
        <Column
          field="qty"
          showFilterMenu={false}
          header="Qty"
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.buy_sell == 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={rowData.qty} fixed={1} />
            </div>
          )}
        />
        <Column
          field="buy_sell"
          showFilterMenu={false}
          header="B/S"
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.buy_sell == 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {rowData.buy_sell == 0 ? "Buy" : "Sell"}
            </div>
          )}
        />
        <Column field="rate" showFilterMenu={false} header="Rate" />
        <Column
          field="trade_msg"
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.trade_msg == "NEW"
                    ? NewTheme.MainColor
                    : rowData.trade_msg == "ADD"
                    ? NewTheme.greencolor
                    : rowData.trade_msg == "REMOVE"
                    ? "#FFA500"
                    : NewTheme.redcolor,
                flexDirection: "column",
                display: "flex",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {rowData.trade_msg}
                <span
                  style={{ color: "black", fontSize: 10, textAlign: "left" }}
                >
                  {rowData.pending_pass == 1 ? " (P)" : null}
                </span>
              </div>
              {rowData.short == "1" ? (
                <span style={{ fontSize: 10, color: "black" }}>
                  {rowData.short_type}
                </span>
              ) : null}
            </div>
          )}
          showFilterMenu={false}
          header="Details"
        />
        <Column
          field="profit_loss"
          showFilterMenu={false}
          header="P/L"
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.profit_loss >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={rowData.profit_loss} />
            </div>
          )}
        />
        <Column
          field="date_created"
          showFilterMenu={false}
          body={(rowData) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                {moment(rowData.date_created).format("DD-MMM")}
                <span style={{ color: NewTheme.MainColor }}>
                  {" " + moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
              {rowData.short == "1" ? (
                <div style={{ fontSize: 10, color: "black" }}>
                  {moment(rowData.previous_trade).format("DD-MMM")}
                  <span style={{ color: NewTheme.MainColor }}>
                    {" " + moment(rowData.previous_trade).format("HH:mm:ss")}
                  </span>
                </div>
              ) : null}
            </div>
          )}
          header="Date"
        />
      </DataTable>
    </>
  );
}
