import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import Php from "../../../Backend/Php";

import moment from "moment";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { NewTheme } from "../../Theme/Theme";
import {
  BoldText,
  FlexContainer,
  FlexItem,
  GrayText,
} from "../../Transaction/Styled";
import No_Image_Available from "./No_Image_Available.jpg";

const php = new Php();

export default function DepositModal({
  setVisible,
  visible,
  position,
  depositData,
}) {
  const [loading, setLoading] = React.useState(false);
  const [adminRemarks, setAdminRemarks] = React.useState("");

  const reject_deposit = () => {
    if (
      !loading &&
      depositData?.id &&
      depositData?.order_id &&
      depositData?.users_id
    ) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        ud_id: depositData?.id,
        u_id: depositData?.users_id,
        o_id: depositData?.order_id,
        a_remarks: adminRemarks,
      };

      php
        .reject_deposit(data)
        .then((r) => {
          setLoading(false);
          if (r.error === "False") {
            window.alert(r.message);
            setVisible();
          } else {
            window.alert(r.message);
          }
        })
        .catch((err) => {
          console.error("Error declining deposit:", err);
          setLoading(false); // Reset loading state on error
          window.alert("An error occurred while declining the deposit.");
        });
    } else {
      console.error("Missing data for deposit acceptance");
      window.alert("Missing data for deposit acceptance. Please try again.");
    }
  };

  const accept_deposit = () => {
    if (
      !loading &&
      depositData?.id &&
      depositData?.order_id &&
      depositData?.users_id
    ) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        ud_id: depositData?.id,
        u_id: depositData?.users_id,
        o_id: depositData?.order_id,
        a_remarks: adminRemarks,
      };

      php
        .accept_deposit(data)
        .then((r) => {
          setLoading(false);
          if (r.error === "False") {
            window.alert(r.message);
            setVisible();
          } else {
            window.alert(r.message);
          }
        })
        .catch((err) => {
          console.error("Error accepting deposit:", err);
          setLoading(false); // Reset loading state on error
          window.alert("An error occurred while accepting the deposit.");
        });
    } else {
      console.error("Missing data for deposit acceptance");
      window.alert("Missing data for deposit acceptance. Please try again.");
    }
  };

  const footerContent = (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {depositData?.status == 0 ? (
        <div>
          <Button
            icon="pi pi-check"
            severity="success"
            onClick={() => accept_deposit()}
            label="Accept"
          />
          <Button
            icon="pi pi-times"
            onClick={() => reject_deposit()}
            severity="danger"
            label="Decline"
          />
        </div>
      ) : (
        <Button
          label={
            depositData?.status_msg +
            " On " +
            moment(depositData?.date_updated).format("DD-MMM-YY HH:mm:ss")
          }
          icon={depositData.status == 1 ? "pi pi-check" : "pi pi-times"}
          severity={depositData.status == 1 ? "success" : "danger"}
        />
      )}
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
    </div>
  );

  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"Deposit Details : " + depositData?.order_id}
        </div>
      }
      visible={visible}
      position={position}
      style={{ width: "70vw", minHeight: "500" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flex: 1, height: "100%" }}>
          <FlexContainer>
            <FlexItem>
              <GrayText>Client</GrayText>
              <BoldText>{depositData?.username?.toUpperCase()}</BoldText>
            </FlexItem>
            <FlexItem>
              <GrayText>Master</GrayText>
              <BoldText>
                {depositData?.master_id > 0
                  ? depositData?.master?.toUpperCase()
                  : "----"}
              </BoldText>
            </FlexItem>
          </FlexContainer>

          <FlexContainer>
            <FlexItem>
              <GrayText>Amount</GrayText>
              <BoldText>
                <NumberFormatIn value={depositData?.amount} />
              </BoldText>
            </FlexItem>
            <FlexItem>
              <GrayText>Users Transaction ID</GrayText>
              <BoldText>
                {depositData?.user_transaction_id != ""
                  ? depositData?.user_transaction_id
                  : "----"}
              </BoldText>
            </FlexItem>
          </FlexContainer>
          <FlexContainer>
            <FlexItem>
              <GrayText>User Remarks</GrayText>
              <BoldText>
                {depositData?.remarks != "" ? depositData?.remarks : "----"}
              </BoldText>
            </FlexItem>
            <FlexItem>
              <GrayText>Admin Remarks</GrayText>
              {depositData.status == 0 ? (
                <InputText
                  style={{ width: "100%", height: 30, fontSize: 14 }}
                  type="text"
                  value={adminRemarks}
                  onChange={(e) => setAdminRemarks(e.target.value)}
                  placeholder="Type Here"
                />
              ) : (
                <BoldText>
                  {depositData?.admin_remarks != ""
                    ? depositData?.admin_remarks
                    : "----"}
                </BoldText>
              )}
            </FlexItem>
          </FlexContainer>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            imageStyle={{ objectFit: "contain", padding: 10 }}
            src={
              depositData.image_link == ""
                ? No_Image_Available
                : "https://gatewayapi.utrade.today/uploads/" +
                  depositData.image_link
            }
            zoomSrc={
              depositData.image_link == ""
                ? No_Image_Available
                : "https://gatewayapi.utrade.today/uploads/" +
                  depositData.image_link
            }
            alt="Image"
            width={400}
            height={200}
            preview
          />
        </div>
      </div>
    </Dialog>
  );
}
