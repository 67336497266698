import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";
import { BoldText, FlexItem } from "../../Users/Overview/Styled";
import Php from "../../../Backend/Php";
import { NewTheme } from "../../Theme/Theme";
import { useNotification } from "../../Notification/NotificationContext";

const php = new Php();

export default function MultiplierModal({
  visible,
  position,
  setVisible,
  fut,
  mcx,
  fo,
  forex,
  crypto,
  stocks,
  reload,
}) {
  const [futMultiplier, setFutMultiplier] = React.useState(fut);
  const [mcxMultiplier, setMcxMultiplier] = React.useState(mcx);
  const [foMultiplier, setFoMultiplier] = React.useState(fo);
  const [forexMultiplier, setForexMultiplier] = React.useState(forex);
  const [cryptoMultiplier, setCryptoMultiplier] = React.useState(crypto);
  const [stocksMultiplier, setStocksMultiplier] = React.useState(stocks);
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();

  const load_admin_multiplier = () => {
    if (!loading) {
      setLoading(true);
      let data2 = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
      };

      php.load_admin_multiplier(data2).then((r) => {
        setLoading(false);
        if (r.error == "False") {
        } else {
          alert(r.message);
        }
      });
    }
  };

  function isValidMultiplier(value) {
    return value !== "" && value !== null && value !== undefined && value > 0;
  }

  const change_default_multiplier = () => {
    if (!isValidMultiplier(futMultiplier)) {
      alert("Please enter valid FUTURE multiplier");
    } else if (!isValidMultiplier(mcxMultiplier)) {
      alert("Please enter valid MCX multiplier");
    } else if (!isValidMultiplier(foMultiplier)) {
      alert("Please enter valid OPTIONS multiplier");
    } else if (!isValidMultiplier(forexMultiplier)) {
      alert("Please enter valid FOREX multiplier");
    } else if (!isValidMultiplier(cryptoMultiplier)) {
      alert("Please enter valid CRYPTO multiplier");
    } else if (!isValidMultiplier(stocksMultiplier)) {
      alert("Please enter valid US STOCKS multiplier");
    } else {
      if (!loading) {
        setLoading(true);
        let data2 = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          a_id: localStorage.getItem("adminsId"),
          fut_multiplier: futMultiplier,
          mcx_multiplier: mcxMultiplier,
          fo_multiplier: foMultiplier,
          forex_multiplier: forexMultiplier,
          crypto_multiplier: cryptoMultiplier,
          stocks_multiplier: stocksMultiplier,
        };

        php.change_default_multiplier(data2).then((r) => {
          setLoading(false);
          if (r.error == "False") {
            reload();
            addNotification(r.message, "success");
          } else {
            alert(r.message);
          }
        });
      }
    }
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => change_default_multiplier()}
        autoFocus
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>Edit Default Multiplier</div>
      }
      visible={visible}
      position={position}
      style={{ width: "35vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Future</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        {/* <span className="p-inputgroup-addon">{props.min_multiplier}</span> */}
        <InputText
          value={futMultiplier}
          onChange={(e) => setFutMultiplier(e.target.value)}
          placeholder="Enter Multiplier"
        />
        {/* <span className="p-inputgroup-addon">{props.max_multiplier}</span> */}
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Mcx</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        {/* <span className="p-inputgroup-addon">{props.min_multiplier}</span> */}
        <InputText
          value={mcxMultiplier}
          onChange={(e) => setMcxMultiplier(e.target.value)}
          placeholder="Enter Multiplier"
        />
        {/* <span className="p-inputgroup-addon">{props.max_multiplier}</span> */}
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Options</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        {/* <span className="p-inputgroup-addon">{props.min_multiplier}</span> */}
        <InputText
          value={foMultiplier}
          onChange={(e) => setFoMultiplier(e.target.value)}
          placeholder="Enter Multiplier"
        />
        {/* <span className="p-inputgroup-addon">{props.max_multiplier}</span> */}
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Forex</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        {/* <span className="p-inputgroup-addon">{props.min_multiplier}</span> */}
        <InputText
          value={forexMultiplier}
          onChange={(e) => setForexMultiplier(e.target.value)}
          placeholder="Enter Forex Multiplier"
        />
        {/* <span className="p-inputgroup-addon">{props.max_multiplier}</span> */}
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Crypto</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        {/* <span className="p-inputgroup-addon">{props.min_multiplier}</span> */}
        <InputText
          value={cryptoMultiplier}
          onChange={(e) => setCryptoMultiplier(e.target.value)}
          placeholder="Enter Crypto Multiplier"
        />
        {/* <span className="p-inputgroup-addon">{props.max_multiplier}</span> */}
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>US STOCKS</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        {/* <span className="p-inputgroup-addon">{props.min_multiplier}</span> */}
        <InputText
          value={stocksMultiplier}
          onChange={(e) => setStocksMultiplier(e.target.value)}
          placeholder="Enter US STOCKS Multiplier"
        />
        {/* <span className="p-inputgroup-addon">{props.max_multiplier}</span> */}
      </div>
    </Dialog>
  );
}
