import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";

import Notification from "./Screens/Notification/Notification";
import { NotificationProvider } from "./Screens/Notification/NotificationContext";
// import { Notifications } from "react-push-notification";

// Import your components for different routes
import "primeicons/primeicons.css";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { io } from "socket.io-client";
import Brokerage from "./Screens/Brokerage/Brokerage";
import Brokers from "./Screens/Brokers/Brokers";
import Holding from "./Screens/Holding/Holding";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login/Login";
import LoginProvider from "./Screens/Login/LoginContext";
import Masters from "./Screens/Master/Masters";
import Pending from "./Screens/Pending/Pending";
import Position from "./Screens/Position/Position";
import MySidebar from "./Screens/Sidebar/MySidebar";
import { SidebarProvider } from "./Screens/Sidebar/SidebarContext";
import Transaction from "./Screens/Transaction/Transaction";
import Users from "./Screens/Users/Users";
import Watchlist from "./Screens/Watchlist/Watchlist";
import SocketContect from "./SocketContext/SocketContext";
import Supers from "./Screens/Super/Supers";
import AdminRules from "./Screens/Extra/AdminRules";
import AdminNotification from "./Screens/Extra/AdminNotification";
import Billsend from "./Screens/BillSend/BillSend";
import Deposit from "./Screens/Deposit/Deposit";
import Withdraw from "./Screens/Withdraw/Withdraw";
import Profile from "./Screens/Profile/Profile";
import PushNotify from "./Screens/Common/PushNotify";
import Ledger from "./Screens/Ledger/Ledger";

var socket = io("https://aemit.utrade.today");

const menuItems = [
  { name: "Home", component: Home },
  { name: "Deposit", component: Deposit },
  { name: "Withdraw", component: Withdraw },
  { name: "Profile", component: Profile },
  { name: "Masters", component: Masters },
  { name: "Users", component: Users },
  { name: "Positions", component: Position },
  { name: "Transactions", component: Transaction },
  { name: "Pendings", component: Pending },
  { name: "Brokers", component: Brokers },
  { name: "Watchlist", component: Watchlist },
  { name: "Brokerage", component: Brokerage },
  { name: "Holdings", component: Holding },
  { name: "Rules", component: AdminRules },
  { name: "Notifications", component: AdminNotification },
  // { name: "Billsend", component: Billsend },
  { name: "Ledger", component: Ledger },
];

function App() {
  socket.on("connect", () => {
    if (localStorage.getItem("isLoggedB2CAdmin") == "1") {
      var get_data = JSON.parse(localStorage.getItem("adminsDetails"));

      socket.emit("login", {
        type: "Server",
        id: localStorage.getItem("adminsId"),
        server: get_data.id,
      });
    }
  });

  // localStorage.clear();
  const isLogged = localStorage.getItem("isLoggedB2CAdmin") === "1";

  return (
    <div className="App">
      {/* <Notifications /> */}
      <SocketContect.Provider value={socket}>
        {/* <PushNotify /> */}
        <SidebarProvider>
          <NotificationProvider>
            <LoginProvider>
              <PrimeReactProvider>
                <Router>
                  {isLogged && <MySidebar />}

                  <Routes>
                    {isLogged ? (
                      menuItems.map((menuItem) => (
                        <Route
                          key={menuItem.name}
                          path={`/${menuItem.name.toLowerCase()}`}
                          element={<menuItem.component />}
                        />
                      ))
                    ) : (
                      <Route path="login" element={<Login />} />
                    )}
                    <Route
                      path="*"
                      element={<Navigate to={isLogged ? "/home" : "/login"} />}
                    />
                  </Routes>
                </Router>
                <Notification />
              </PrimeReactProvider>
            </LoginProvider>
          </NotificationProvider>
        </SidebarProvider>
      </SocketContect.Provider>
    </div>
  );
}

export default App;
