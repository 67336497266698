import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { NewTheme } from "../../Theme/Theme";
import DeleteModal from "./DeleteModal";

const php = new Php();
const height = window.innerHeight;

export default function TransactionModal(props) {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [transactionData, setTransactionData] = React.useState([]);
  const [transactionDeleteData, setTransactionDeleteData] = React.useState([]);

  const [totalProfitLoss, setProfitLoss] = React.useState(0);
  const [totalBrokerage, setTotalBrokerage] = React.useState(0);
  const [totalVolume, setTotalVolume] = React.useState(0);

  React.useEffect(() => {
    load_transactions_history();
  }, []);

  const load_transactions_history = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        p_id: props.positionId,
      };

      php.load_transactions_history(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setTransactionData(r.transactions);
          setTotalBrokerage(parseFloat(r.brokerage).toFixed(2));
          setTotalVolume(parseFloat(r.volume).toFixed(0));
          setProfitLoss(parseFloat(r.profit_loss).toFixed(3));
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const [visible, setVisible] = React.useState(false);
  const [position, setPosition] = React.useState("top");

  const show = (deleteData) => {
    setVisible(true);
    setTransactionDeleteData(deleteData);
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"Transaction History "}
        </div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "100vw" }}
      breakpoints={{ "960px": "95vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          position: "absolute",
          top: 20,
          right: 120,
          fontWeight: "bold",
          color: NewTheme.MainColor,
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 50,
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: 12,
            }}
          >
            Profitloss
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              color:
                totalProfitLoss >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
            }}
          >
            {parseFloat(totalProfitLoss).toFixed(2)}
          </div>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 50,
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 12 }}>Brokerage</div>
          <div
            style={{ fontSize: 16, color: NewTheme.greencolor, marginTop: 3 }}
          >
            {totalBrokerage}
          </div>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 50,
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 12, color: "black" }}>Volume</div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
            }}
          >
            {totalVolume}
          </div>
        </div>
      </div>
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollable
        scrollHeight={height - 120}
        size="small"
        // filters={filters}
        // filterDisplay="row"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        value={!loading && transactionData}
        style={{ fontWeight: "bold", fontSize: 14 }}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        globalFilterFields={["username", "symbol"]}
        paginator
        rows={15}
        rowsPerPageOptions={[10, 15, 30, 50, 100]}
      >
        <Column field="super" showFilterMenu={false} header="Super" sortable />
        <Column
          field="master"
          showFilterMenu={false}
          header="Master"
          sortable
        />

        <Column
          field="username"
          showFilterMenu={false}
          header="Client"
          sortable
        />
        <Column
          field="symbol"
          showFilterMenu={false}
          header="Symbols"
          sortable
          body={(rowData) => (
            <>
              <div
                style={{
                  color: NewTheme.MainColor,
                }}
              >
                {rowData.symbol}
              </div>
              <span style={{ color: "gray" }}>
                {moment(rowData.expiry_date).format("DD-MMM")}
              </span>
            </>
          )}
        />
        <Column
          field="qty"
          showFilterMenu={false}
          header="Qty"
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.buy_sell == 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {rowData.qty}
            </div>
          )}
          sortable
        />
        <Column
          field="buy_sell"
          showFilterMenu={false}
          header="B/S"
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.buy_sell == 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {rowData.buy_sell == 0 ? "Buy" : "Sell"}
            </div>
          )}
          sortable
        />
        <Column
          field="position_rate"
          showFilterMenu={false}
          sortable
          header="Position Rate"
        />
        <Column field="rate" sortable showFilterMenu={false} header="Rate" />
        <Column
          field="profit_loss"
          showFilterMenu={false}
          header="P/L"
          sortable
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.profit_loss >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              {rowData.profit_loss}
            </div>
          )}
        />

        <Column
          field="brokerage_amt"
          body={(rowData) => (
            <>
              <div>{rowData.brokerage_amt}</div>
            </>
          )}
          showFilterMenu={false}
          header="Brokerage"
          sortable
        />

        <Column
          field="volume"
          sortable
          showFilterMenu={false}
          header="Volume "
        />

        <Column
          field="deposit"
          sortable
          showFilterMenu={false}
          header="Deposit "
        />

        <Column
          field="trade_msg"
          sortable
          showFilterMenu={false}
          header="Details"
        />
        <Column
          field="date_created"
          style={{ wordWrap: "break-word" }}
          showFilterMenu={false}
          header="Date/Time"
          body={(rowData) => (
            <div>{moment(rowData.date_created).format("DD-MMM HH:mm:ss")}</div>
          )}
          sortable
        />
        <Column
          field="date_created"
          style={{ wordWrap: "break-word" }}
          showFilterMenu={false}
          header=""
          body={(rowData) => (
            <div style={{ display: "flex", gap: 5 }}>
              <Button
                type="button"
                icon="pi pi-times"
                severity="danger"
                style={{ height: 25, width: 25 }}
                onClick={() => show(rowData)}
                data-pr-tooltip="PDF"
              />
            </div>
          )}
        />
      </DataTable>
      {visible && (
        <DeleteModal
          key={new Date()}
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          load_transactions_history={() => {
            load_transactions_history();
            props.reload();
          }}
          transactionDeleteData={transactionDeleteData}
        />
      )}
    </Dialog>
  );
}
