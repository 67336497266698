import React from "react";
import { NewTheme } from "../Theme/Theme";
import Php from "../../Backend/Php";
import UpdateNotification from "./Notification";
import { useNavigate } from "react-router-dom";

const php = new Php();
export default function SubStatus() {
  const [deposit, setDeposit] = React.useState(0);
  const [withdraw, setWithdraw] = React.useState(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    load_deposit_withdraw();
  }, []);

  const load_deposit_withdraw = () => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      a_id: localStorage.getItem("adminsId"),
    };

    php.load_deposit_withdraw(data).then((r) => {
      if (r.error == "False") {
        setDeposit(r.deposit);
        setWithdraw(r.withdraw);
      } else {
      }
    });
  };

  return (
    <div style={{ display: "flex" }}>
      <UpdateNotification update={() => load_deposit_withdraw()} />
      <div
        onClick={() => navigate("/Deposit")}
        style={{ width: 40, fontSize: 20, position: "relative" }}
      >
        D
        <div
          style={{
            position: "absolute",
            top: -5,
            right: 0,
            fontSize: 12,
            background: NewTheme.greencolor,
            color: "white",
            fontWeight: "bold",
            padding: 3,
            borderRadius: 100,
            boxSizing: "border-box",
          }}
        >
          {deposit}
        </div>
      </div>
      <div
        onClick={() => navigate("/Withdraw")}
        style={{ width: 40, fontSize: 20, position: "relative" }}
      >
        W
        <div
          style={{
            position: "absolute",
            top: -5,
            right: 0,
            fontSize: 12,
            background: NewTheme.redcolor,
            color: "white",
            fontWeight: "bold",
            padding: 3,
            borderRadius: 100,
            boxSizing: "border-box",
          }}
        >
          {withdraw}
        </div>
      </div>
    </div>
  );
}
