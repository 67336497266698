import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import { InputText } from "primereact/inputtext";
import AddModal from "./Modal/AddModal";
import EditModal from "./Modal/EditModal";
const php = new Php();

const height = window.innerHeight;

export default function Billsend() {
  const [billSend, setBillSend] = useState([]);
  const [billSendId, setBillSendId] = useState(0);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  useEffect(() => {
    load_bill_send();
  }, []);

  const load_bill_send = () => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      a_id: localStorage.getItem("adminsId"),
    };

    php.load_bill_send(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setBillSend(r.bill_send);
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 10,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
        }}
      >
        <InputText
          style={{ width: 140 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
        <Button
          type="button"
          icon="pi pi-plus"
          severity="warning"
          label={"Add"}
          style={{
            width: 100,
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={() => show()}
          data-pr-tooltip="PDF"
        />
      </div>
    </div>
  );

  const handleBillSend = (data) => {
    if (data.type == "brokerbill") {
      let link =
        "https://testapi.dreamtrade.biz/ExtraPhpApis/BrokerBillPdf.php?username=" +
        data.username +
        "&server=" +
        data.server +
        "&ids=" +
        data.id;
      return link;
    }
    if (data.type == "masterbill") {
      let link =
        "https://testapi.dreamtrade.biz/ExtraPhpApis/MasterBillPdf.php?username=" +
        data.username +
        "&server=" +
        data.server +
        "&ids=" +
        data.id;
      return link;
    }

    if (data.type == "superbill") {
      let link =
        "https://testapi.dreamtrade.biz/ExtraPhpApis/SuperBillPdf.php?username=" +
        data.username +
        "&server=" +
        data.server +
        "&ids=" +
        data.id;
      return link;
    }

    if (data.type == "brokersummary") {
      let link =
        "https://testapi.dreamtrade.biz/ExtraPhpApis/BrokerSummaryPdf.php?username=" +
        data.username +
        "&server=" +
        data.server +
        "&ids=" +
        data.id;
      return link;
    }
  };

  const deleteEntry = (id) => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      a_id: localStorage.getItem("adminsId"),
      e_id: id,
    };

    php.delete_bill_send_entry(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        load_bill_send();
        addNotification(r.message, "success");
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const handleBrokerageReturn = (id) => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      a_id: localStorage.getItem("adminsId"),
      e_id: id,
    };

    php.bill_send_brokerage(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        load_bill_send();
        addNotification(r.message, "success");
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setVisible(true);
  };

  const showEdit = (position_id) => {
    setBillSendId(position_id);
    setVisibleEdit(true);
  };

  return (
    <div
      className="card"
      style={{
        // background: "black",
        // padding: "0.5rem",
        paddingTop: 0,
        // borderRadius: "10px",
        // marginBottom: "1rem",
        overflow: "hidden",
      }}
    >
      <TopbarMost name="Bill Send" cmp={filterbar} />

      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 150}
          size="small"
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={billSend}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginator
          rows={10}
          globalFilterFields={[
            "username",
            "server",
            "wa1",
            "wa2",
            "wa3",
            "bhaag",
            "type",
          ]}
          loading={loading}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 25, 50, 100]}
        >
          <Column
            field="type"
            showFilterMenu={false}
            header="Bill Type"
            body={(rowData) => <div>{rowData?.type?.toUpperCase()}</div>}
          />
          <Column field="username" showFilterMenu={false} header="Username" />
          <Column field="server" showFilterMenu={false} header="Server" />
          <Column field="bhaag" showFilterMenu={false} header="Bhaag" />

          <Column field="wa1" showFilterMenu={false} header="WA 1" />
          <Column field="wa2" showFilterMenu={false} header="WA 2" />
          <Column field="wa3" showFilterMenu={false} header="WA 3" />

          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM ")}
                <br />
                <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
            )}
            sortable
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Brokerage Return"
            body={(rowData) => (
              <div style={{ display: "flex" }}>
                <Button
                  type="button"
                  label={rowData.brokreturn == 0 ? "No" : "Yes"}
                  severity={rowData.brokreturn == 0 ? "danger" : "success"}
                  style={{ height: 25, fontSize: 12 }}
                  onClick={() => handleBrokerageReturn(rowData.id)}
                />
              </div>
            )}
            sortable
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header=""
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                <a
                  target="_blank"
                  // rel="noopener noreferrer"
                  // className="p-button font-bold"
                  href={handleBillSend(rowData)}
                  style={{ height: 25 }}
                >
                  PDF
                </a>
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  severity="info"
                  style={{ height: 25, width: 25 }}
                  onClick={() => showEdit(rowData.id)}
                />
                <Button
                  type="button"
                  icon="pi pi-trash"
                  severity="danger"
                  style={{ height: 25, width: 25 }}
                  onClick={() => deleteEntry(rowData.id)}
                />
              </div>
            )}
          />
        </DataTable>
      </div>
      {visible && (
        <AddModal
          key={new Date()}
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          reload={() => load_bill_send()}
        />
      )}
      {visibleEdit && (
        <EditModal
          key={new Date()}
          setVisible={() => {
            setVisibleEdit(false);
            load_bill_send();
          }}
          position={position}
          visible={visibleEdit}
          billSendId={billSendId}
          reload={() => load_bill_send()}
        />
      )}
    </div>
  );
}
