import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

import { InputText } from "primereact/inputtext";
import Php from "../../Backend/Php";
import { useNotification } from "../Notification/NotificationContext";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { NewTheme } from "../Theme/Theme";
import moment from "moment/moment";
import { Calendar } from "primereact/calendar";
import TopbarMost from "../Common/TopbarMost";
import Filter from "./Filter/Filter";
import "jspdf-autotable";
import jsPDF from "jspdf";
import NumberFormatIn from "../Common/NumberFormatIn";
const php = new Php();

const height = window.innerHeight;

export default function Pending() {
  const [pendingData, setPendingData] = useState([]);
  const [pendingDataFilter, setPendingDataFilter] = useState([]);

  const [usersFilter, setUsersFilter] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const [pendingStatusCount, setPendingStatusCount] = useState(0);
  const [executedStatusCount, setExecutedStatusCount] = useState(0);
  const [cancelledStatusCount, setCancelledStatusCount] = useState(0);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [dropdown, setDropdown] = useState("");
  const dt = useRef(null);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [date, setDate] = useState([new Date(), new Date()]);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filterSymbol, setFilterSymbol] = React.useState([]);
  const [filterUser, setFilterUser] = React.useState([]);

  useEffect(() => {
    load_pendings();
  }, []);

  const load_pendings = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        from_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[0]).format("YYYY-MM-DD 00:00:00"),
        to_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[1]).format("YYYY-MM-DD 23:59:59"),
        c_id:
          selectedUser?.id == undefined || e == "clear" ? 0 : selectedUser?.id,
      };

      php.load_pendings(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          // Create a Set to store unique symbols
          const uniqueSymbols = new Set();
          const uniqueUsers = new Set();
          const array = r.pendings;
          // Iterate over the data and add symbols to the Set
          array.forEach((item) => {
            uniqueSymbols.add(item.symbol);
            uniqueUsers.add(item.username);
          });

          setFilterSymbol(Array.from(uniqueSymbols));
          setFilterUser(Array.from(uniqueUsers));

          setUsersFilter(r.users);
          setPendingDataFilter(r.pendings);
          setPendingData(r.pendings);

          let pendingStatus = r.pendings.filter((x) => x.status == "0");
          let executedStatus = r.pendings.filter((x) => x.status == "1");
          let cancelledStatus = r.pendings.filter((x) => x.status == "2");

          setPendingStatusCount(pendingStatus.length);
          setExecutedStatusCount(executedStatus.length);
          setCancelledStatusCount(cancelledStatus.length);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const cols = [
    { dataKey: "username", title: "Username" },
    { dataKey: "symbol", title: "Symbol" },
    { dataKey: "qty", title: "Qty" },
    { dataKey: "buy_sell", title: "B/S" },
    { dataKey: "price", title: "Price" },
    { dataKey: "high_rate", title: "High" },
    { dataKey: "low_rate", title: "Low" },
    { dataKey: "highlow_mid", title: "HL/MID" },
    { dataKey: "type", title: "Type" },
    { dataKey: "tick_time", title: "Tick Time" },
    { dataKey: "time_buy", title: "Process Time" },
    { dataKey: "process_msg", title: "Details" },
    { dataKey: "date_created", title: "Date" },
  ];

  const exportPdf = () => {
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });

    doc.setFontSize(16);
    doc.text("Pending Report", 15, 15);

    pendingData.forEach((row) => {
      // Convert the value to a number for loose equality check
      const highLowValue = Number(row.highlow_mid);
      const buySellValue = Number(row.buy_sell);
      const slTpValue = Number(row.sl_tp);

      // Update the values based on conditions
      row.highlow_mid = highLowValue == 0 ? "High-Low" : "Mid-Limit";
      row.buy_sell = buySellValue == 0 ? "Buy" : "Sell";
      row.type = buySellValue == 0 ? "Buy" : "Sell"; // Assuming 'type' is a string property

      // Determine 'sl_tp' based on its value
      if (slTpValue == 0) {
        row.type += "-Stop";
      } else {
        row.type += "-Target";
      }
    });

    const headerStyles = {
      fillColor: "#4a6ba1",
      textColor: "#ffffff",
    };

    // Add content to the PDF using autoTable with modified data and custom header styles
    const tableStartY = 25; // Adjust the startY value based on the header size and layout
    doc.autoTable(cols, pendingData, { startY: tableStartY, headerStyles });

    var pdfName =
      selectedUser?.username != undefined
        ? selectedUser?.username.toUpperCase() + " - Pending Report"
        : "(S)" +
          localStorage.getItem("server").toUpperCase() +
          " (M)" +
          localStorage.getItem("adminsUsername")?.toUpperCase() +
          " - Pending Report";
    // Save or display the PDF

    doc.save(pdfName + ".pdf");
  };

  const cancel_pending = (pending_id) => {
    if (window.confirm("Are you sure you want to CANCEL this PENDING ?")) {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          a_id: localStorage.getItem("adminsId"),
          pending_id: pending_id,
        };

        php.cancel_users_pending(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            addNotification(r.message, "success");
            load_pendings();
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const pass_pending = (pending_id) => {
    if (window.confirm("Are you sure you want to MANUAL PASS this PENDING ?")) {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          a_id: localStorage.getItem("adminsId"),
          pending_id: pending_id,
        };

        php.pass_pending(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            addNotification(r.message, "success");
            load_pendings();
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Executed</div>
        <div style={{ fontSize: 18, color: NewTheme.greencolor }}>
          <NumberFormatIn value={executedStatusCount} />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Cancelled</div>
        <div style={{ fontSize: 18, color: NewTheme.redcolor }}>
          <NumberFormatIn value={cancelledStatusCount} />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Pending</div>
        <div style={{ fontSize: 18, color: NewTheme.MainColor }}>
          <NumberFormatIn value={pendingStatusCount} />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
        }}
      >
        <InputText
          style={{ width: 140 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
      </div>
      <Filter
        selectedUser={selectedUser}
        usersFilter={usersFilter}
        setSelectedUser={(e) => setSelectedUser(e)}
        date={date}
        setDate={(e) => setDate(e)}
        clearFilter={() => load_pendings("clear")}
        load_pendings={load_pendings}
      />

      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="PDF"
          severity="warning"
          style={{
            height: 25,
            width: 80,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
    </div>
  );

  return (
    <div
      className="card"
      style={{
        padding: "0.5rem",
        paddingTop: 0,
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <TopbarMost name="Pendings" cmp={filterbar} />

      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 150}
          size="small"
          filters={filters}
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={pendingData}
          style={{ fontWeight: "bold", fontSize: 14, borderColor: "red" }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          // globalFilterFields={["username", "symbol"]}
          paginator
          rows={10}
          loading={loading}
          rowsPerPageOptions={[10, 25, 50, 100]}
        >
          <Column
            field="master"
            showFilterMenu={false}
            header="Master"
            sortable
          />
          <Column
            field="username"
            showFilterMenu={false}
            header="Client"
            sortable
          />
          <Column
            field="symbol"
            showFilterMenu={false}
            header="Symbols"
            sortable
            body={(rowData) => (
              <>
                <div style={{ color: NewTheme.MainColor }}>
                  {rowData.symbol}
                </div>
                <div
                  style={{
                    marginTop: 0,
                    paddingTop: 0,
                    color: "gray",
                    fontWeight: "bold",
                    fontSize: 10,
                    height: "100%",
                  }}
                >
                  {moment(rowData.expiry_date).format("DD-MMM-YY")}
                </div>
              </>
            )}
          />
          <Column
            field="qty"
            body={(rowData) => (
              <div>
                <NumberFormatIn value={rowData.qty} fixed={1} />
              </div>
            )}
            showFilterMenu={false}
            header="Qty"
          />
          <Column
            field="buy_sell"
            showFilterMenu={false}
            header="B/S"
            body={(rowData) => (
              <div
                style={{
                  color:
                    rowData.buy_sell == 0
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                {rowData.buy_sell == 0 ? "Buy" : "Sell"}
              </div>
            )}
          />
          <Column field="price" filter showFilterMenu={false} header="Price" />
          <Column
            field="high_rate"
            filter
            showFilterMenu={false}
            header="High"
          />
          <Column field="low_rate" filter showFilterMenu={false} header="Low" />

          <Column
            field="highlow_mid"
            body={(rowData) => (
              <div>{rowData.highlow_mid == 0 ? "High-Low" : "Mid-Limit"}</div>
            )}
            showFilterMenu={false}
            header="HL/MID"
          />
          <Column
            field="sl_tp"
            body={(rowData) => (
              <div>
                <span>{rowData.buy_sell == 0 ? "Buy " : "Sell "}</span>
                <span>{rowData.sl_tp == 0 ? "Stop" : "Target"}</span>
              </div>
            )}
            showFilterMenu={false}
            header="Type"
          />
          <Column
            field="tick_time"
            showFilterMenu={false}
            body={(rowData) =>
              rowData.tick_time != null ? (
                <div>
                  {moment(rowData.tick_time).format("DD-MMM ")}
                  <br />
                  <span style={{ color: NewTheme.MainColor }}>
                    {moment(rowData.tick_time).format("HH:mm:ss")}
                  </span>
                </div>
              ) : (
                "------"
              )
            }
            header="Tick Time"
          />
          <Column
            field="process_time"
            showFilterMenu={false}
            body={(rowData) =>
              rowData.time_buy != null ? (
                <div>
                  {moment(rowData.time_buy).format("DD-MMM ")}
                  <br />
                  <span style={{ color: NewTheme.MainColor }}>
                    {moment(rowData.time_buy).format("HH:mm:ss")}
                  </span>
                </div>
              ) : (
                "------"
              )
            }
            header="Process Time"
          />

          <Column field="process_msg" showFilterMenu={false} header="Details" />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM ")}
                <br />
                <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
            )}
            sortable
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header=""
            body={(rowData) =>
              rowData.status == 0 ? (
                <div style={{ display: "flex", gap: 5 }}>
                  <Button
                    type="button"
                    icon="pi pi-times"
                    severity="danger"
                    style={{ height: 25, width: 25 }}
                    onClick={() => cancel_pending(rowData.id)}
                    data-pr-tooltip="PDF"
                  />
                  <Button
                    type="button"
                    icon="pi pi-check"
                    severity="success"
                    style={{ height: 25, width: 25 }}
                    onClick={() => pass_pending(rowData.id)}
                    data-pr-tooltip="PDF"
                  />
                </div>
              ) : null
            }
          />
        </DataTable>
      </div>
    </div>
  );
}
