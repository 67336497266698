import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import * as ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import Filter from "./Filter/Filter";
import VolumeModal from "./VolumeModal/VolumeModal";
import TransactionModal from "./VolumeModal/TransactionModal";
import NumberFormatIn from "../Common/NumberFormatIn";
import { InputText } from "primereact/inputtext";
const php = new Php();

const height = window.innerHeight;

export default function Ledger() {
  const [transactionData, setTransactionData] = useState([]);
  const [transactionDataFilter, setTransactionDataFilter] = useState([]);

  const [usersFilter, setUsersFilter] = useState([]);
  const [usersFilter2, setUsersFilter2] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const [mastersFilter, setMastersFilter] = useState([]);
  const [selectedMaster, setSelectedMaster] = useState([]);

  const [mastersFilterMain, setMastersFilterMain] = useState([]);

  const [supersFilter, setSupersFilter] = useState([]);
  const [selectedSuper, setSelectedSuper] = useState([]);

  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [totalDiff, setTotalDiff] = useState(0);

  const [futVolume, setFutVolume] = useState(0);
  const [mcxVolume, setMcxVolume] = useState(0);
  const [mcxLot, setMcxLot] = useState(0);
  const [foVolume, setFoVolume] = useState(0);

  const [positionId, setPositionId] = useState(0);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [date, setDate] = useState([new Date(), new Date()]);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  useEffect(() => {
    load_admin_ledger();
  }, []);

  const load_admin_ledger = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        from_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[0]).format("YYYY-MM-DD 00:00:00"),
        to_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[1]).format("YYYY-MM-DD 23:59:59"),
        c_id:
          selectedUser?.id == undefined || e == "clear" ? 0 : selectedUser?.id,
        m_id:
          selectedMaster?.id == undefined || e == "clear"
            ? 0
            : selectedMaster?.id,
        s_id:
          selectedSuper?.id == undefined || e == "clear"
            ? 0
            : selectedSuper?.id,
      };

      php.load_admin_ledger(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          if (e == "clear") {
            setSelectedMaster({});
            setSelectedUser({});
            setSelectedSuper({});
            setDate([new Date(), new Date()]);
          }

          setTotalWithdraw(parseFloat(r.withdraw).toFixed(2));
          // setTotalDiff(parseFloat(r.volume).toFixed(0));
          setTotalDeposit(parseFloat(r.deposit).toFixed(2));

          setUsersFilter(r.users);
          setUsersFilter2(r.users);

          setMastersFilter(r.masters);
          setMastersFilterMain(r.masters);

          setSupersFilter(r.supers);
          setTransactionDataFilter(r.ledger);
          setTransactionData(r.ledger);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const cols = [
    { dataKey: "super", title: "Super" },
    { dataKey: "master", title: "Master" },
    { dataKey: "username", title: "Username" },
    { dataKey: "symbol", title: "Symbol" },
    { dataKey: "qty", title: "Qty" },
    { dataKey: "buy_Sell", title: "B/S" },
    { dataKey: "rate", title: "Rate" },
    { dataKey: "profit_loss", title: "P/L" },
    { dataKey: "brokerage_amt", title: "Brokerage" },
    { dataKey: "volume", title: "Volume" },
    { dataKey: "deposit", title: "Deposit" },
    { dataKey: "trade_msg", title: "Details" },
    { dataKey: "date_created", title: "Date" },
  ];

  const exportPdf = () => {
    return;
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });
    const additionalCols = ["Overview", "", ""];

    var additionalData = [
      [1, "Profit/Loss", parseFloat(totalDeposit)],
      [2, "Brokerage", parseFloat(totalWithdraw)],
      [3, "Volume", parseFloat(totalDiff)],
      [4, "Fut Volume", parseFloat(futVolume)],
      [5, "Option Lot Wise", parseFloat(foVolume).toFixed(1) + " Lot"],
      [6, "Mcx Lot Wise", parseFloat(mcxLot).toFixed(1) + " Lot"],
    ];

    if (mcxVolume > 0) {
      additionalData.push([
        7,
        "Mcx Volume Wise",
        parseFloat(mcxVolume).toFixed(1),
      ]);
    }

    doc.autoTable({
      head: [additionalCols],
      body: additionalData,
    });
    // Create a new jsPDF instance with landscape orientation

    // Add content to the PDF using autoTable
    doc.autoTable(cols, transactionData);

    var pdfName =
      selectedMaster?.username != undefined
        ? selectedMaster?.username + " - Transaction"
        : selectedUser?.username != undefined
        ? selectedUser?.username + " - Transaction"
        : localStorage.getItem("server")?.toUpperCase() + " - Transaction";
    // Save or display the PDF

    doc.save(pdfName + ".pdf");
  };

  const [visible, setVisible] = useState(false);
  const [visibleTransactionModal, setVisibleTransactionModal] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setVisible(true);
  };

  const showTransactionModal = (position_id) => {
    setPositionId(position_id);
    setVisibleTransactionModal(true);
  };

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the actual columns you want to export
    const colsToExport = [
      "master",
      "username",
      "deposit",
      "withdraw",
      "admin_remarks",
      "remarks",
      "old_balance",
      "new_balance",
      "date_created",
      "in_out",
    ];

    // Specify the display names for the headers
    const headerDisplayNames = [
      "Master",
      "Username",
      "Deposit",
      "Withdraw",
      "Admin Remarks",
      "Remarks",
      "Old Balance",
      "New Balance",
      "Date Created",
    ];

    const headerRow = worksheet
      .addRow([
        selectedMaster?.username != undefined
          ? selectedMaster?.username + " - Ledger"
          : selectedUser?.username != undefined
          ? selectedUser?.username + " - Ledger"
          : localStorage.getItem("server")?.toUpperCase() + " - Ledger",
      ])
      .commit();
    worksheet.mergeCells(`A${worksheet.rowCount}:L${worksheet.rowCount}`);
    const mergedRange = worksheet.getCell(
      `A${worksheet.rowCount}:L${worksheet.rowCount}`
    );
    mergedRange.alignment = { horizontal: "center" };
    mergedRange.font = { bold: true, size: 16 };

    // Add empty rows between summary and data
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    // Add column headers to the worksheet with display names
    const headers = worksheet.addRow(headerDisplayNames);

    headers.eachCell((cell) => {
      cell.font = {
        bold: true,
      };
    });

    // Extract only the desired columns from transactionData
    const filteredData = transactionData.map((item) => {
      // Use reduce to accumulate the desired columns and add deposit/withdraw based on in_out
      return colsToExport.reduce((acc, col) => {
        acc[col] = item[col]; // Add the selected columns to acc

        // Add deposit or withdraw based on in_out value
        if (col == "in_out") {
          if (item.in_out == 0) {
            acc.deposit = item.amount; // Set deposit to amount if in_out is 0
            acc.withdraw = 0; // Optionally set withdraw to 0
          } else {
            acc.withdraw = item.amount; // Set withdraw to amount if in_out is not 0
            acc.deposit = 0; // Optionally set deposit to 0
          }
        }

        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Set column width to 16 for all columns
    worksheet.columns.forEach((column) => {
      column.width = 16;
    });

    const depositIndex = colsToExport.indexOf("deposit");
    const withdrawIndex = colsToExport.indexOf("withdraw");
    // const inOutIndex = colsToExport.indexOf("in_out");
    // const amountIndex = colsToExport.indexOf("amount");

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      const depositValue = row.getCell(depositIndex + 1).value;
      const withdrawValue = row.getCell(withdrawIndex + 1).value;

      const formatAsNumber = (cell, value) => {
        if (value !== null && !isNaN(value) && rowNumber > 4) {
          cell.value = rowNumber > 4 ? Number(value) : value;
          cell.numFmt = rowNumber > 4 ? "#,##0.00" : undefined; // Adjust the number format as needed
        }
      };

      formatAsNumber(row.getCell(depositIndex + 1), depositValue);
      formatAsNumber(row.getCell(withdrawIndex + 1), withdrawValue);

      if (depositValue !== null && !isNaN(depositValue) && rowNumber > 4) {
        const depositColor = NewTheme.greencolorExcel; // Green for positive, Red for negative
        row.getCell(depositIndex + 1).font = {
          color: { argb: depositColor },
        };
      }
      if (withdrawValue !== null && !isNaN(withdrawValue) && rowNumber > 4) {
        const withdrawColor = NewTheme.redcolorExcel; // Green for positive, Red for negative
        row.getCell(withdrawIndex + 1).font = {
          color: { argb: withdrawColor },
        };
      }
    });

    // Add empty rows between data and footer
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    var fileName =
      selectedMaster?.username != undefined
        ? selectedMaster?.username + " - Ledger"
        : selectedUser?.username != undefined
        ? selectedUser?.username + " - Ledger"
        : localStorage.getItem("server")?.toUpperCase() + " - Ledger";

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const filterSuper = (superData) => {
    let super_id = superData?.id;
    if (super_id > 0) {
      let newMasterArray = mastersFilterMain.filter(
        (x) => x.super_id == super_id
      );
      setMastersFilter(newMasterArray);
      let newUserArray = usersFilter2.filter((x) => x.super_id == super_id);
      setUsersFilter(newUserArray);
    } else {
      setMastersFilter(mastersFilterMain);
      setUsersFilter(usersFilter2);
      setSelectedMaster({});
      setSelectedUser({});
    }
    setSelectedSuper(superData);
  };

  const filterMaster = (masterData) => {
    let master_id = masterData?.id;
    if (master_id > 0) {
      let newUserArray = usersFilter2.filter((x) => x.master_id == master_id);
      setUsersFilter(newUserArray);
    } else if (selectedSuper?.id > 0) {
      filterSuper(selectedSuper);
      setSelectedUser({});
    } else {
      setUsersFilter(usersFilter2);
      setSelectedUser({});
    }
    setSelectedMaster(masterData);
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 50,
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: 12,
            }}
          >
            Deposit
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              color:
                totalDeposit >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
            }}
          >
            <NumberFormatIn value={parseFloat(totalDeposit).toFixed(2)} />
          </div>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 50,
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 12 }}>Withdraw</div>
          <div style={{ fontSize: 16, color: NewTheme.redcolor, marginTop: 3 }}>
            <NumberFormatIn value={totalWithdraw} />
          </div>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 50,
            flexDirection: "column",
          }}
          onClick={() => show()}
        >
          <div style={{ fontSize: 12 }}>Difference</div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              color:
                parseFloat(totalDeposit) + parseFloat(totalWithdraw) >= 0
                  ? NewTheme.greencolor
                  : NewTheme.redcolor,
            }}
          >
            <NumberFormatIn
              value={parseFloat(totalDeposit) + parseFloat(totalWithdraw)}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 50,
        }}
      >
        <Button
          type="button"
          label="PDF"
          severity="warning"
          style={{
            width: 100,
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="XLS"
          severity="primary"
          style={{
            width: 100,
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={exportExcel}
          data-pr-tooltip="EXCEL"
        />
      </div>
    </div>
  );

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const searchBar = (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: 20,
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 20,
          }}
        >
          <InputText
            style={{ width: 140, height: 30 }}
            type="search"
            value={value || ""}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder="Search"
          />
        </div>
        <Filter
          selectedUser={selectedUser}
          selectedMaster={selectedMaster}
          selectedSuper={selectedSuper}
          usersFilter={usersFilter}
          mastersFilter={mastersFilter}
          supersFilter={supersFilter}
          setSelectedUser={(e) => setSelectedUser(e)}
          setSelectedMaster={(e) => filterMaster(e)}
          setSelectedSuper={(e) => filterSuper(e)}
          date={date}
          setDate={(e) => setDate(e)}
          clearFilter={() => load_admin_ledger("clear")}
          load_admin_ledger={load_admin_ledger}
        />
      </div>
    </>
  );

  return (
    <div
      className="card"
      style={{
        // background: "black",
        // padding: "0.5rem",
        paddingTop: 0,
        // borderRadius: "10px",
        // marginBottom: "1rem",
        overflow: "hidden",
      }}
    >
      <TopbarMost background={"#ffffff"} name="Ledger" cmp={filterbar} />

      <TopbarMost
        height={45}
        background={"#ffffff"}
        noSidebar
        cmp={searchBar}
      />

      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 160}
          size="small"
          filters={filters}
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={!loading && transactionData}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          globalFilterFields={[
            "username",
            "master",
            // "super",
            "admin_remarks",
            "remarks",
            "date_created",
            "amount",
          ]}
          paginator
          rows={15}
          loading={loading}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 15, 30, 50, 100]}
        >
          {/* <Column
            field="super"
            showFilterMenu={false}
            header="super"
            sortable
          /> */}
          <Column
            field="master"
            showFilterMenu={false}
            header="Master"
            sortable
            body={(rowData) => <div>{rowData.master}</div>}
          />

          <Column
            field="username"
            showFilterMenu={false}
            header="Client"
            sortable
            body={(rowData) => <div>{rowData.username}</div>}
          />

          <Column
            field="Remarks"
            body={(rowData) => <div>{rowData.remarks}</div>}
            showFilterMenu={false}
            header="Remarks"
          />

          <Column
            field="Admin Remarks"
            body={(rowData) => <div>{rowData.admin_remarks}</div>}
            showFilterMenu={false}
            header="Admin Remarks"
          />

          <Column
            field="Deposit"
            filter
            showFilterMenu={false}
            header="Deposit"
            body={(rowData) => (
              <>
                <NumberFormatIn
                  value={
                    rowData.in_out == "0" ? parseFloat(rowData.amount) : "0"
                  }
                />
              </>
            )}
          />

          <Column
            field="Withdraw"
            filter
            showFilterMenu={false}
            header="Withdraw"
            body={(rowData) => (
              <>
                <NumberFormatIn
                  value={
                    rowData.in_out == "1" ? parseFloat(rowData.amount) : "0"
                  }
                />
              </>
            )}
          />

          <Column
            field="Balance"
            filter
            showFilterMenu={false}
            header="Balance"
            body={(rowData) => (
              <>
                <div>
                  <span style={{ color: "gray" }}>B:</span>
                  <NumberFormatIn value={parseFloat(rowData.old_balance)} />
                </div>
                <div>
                  <span style={{ color: "gray" }}>A:</span>
                  <NumberFormatIn value={parseFloat(rowData.new_balance)} />
                </div>
              </>
            )}
          />

          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM HH:mm:ss")}
                {/* <br />
                <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.date_created).format("HH:mm:ss")}
                </span> */}
              </div>
            )}
            sortable
          />
          {/* <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header=""
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                <Button
                  type="button"
                  icon="pi pi-info"
                  severity="info"
                  style={{ height: 25, width: 25 }}
                  onClick={() => showTransactionModal(rowData.position_id)}
                  data-pr-tooltip="PDF"
                />
              </div>
            )}
          /> */}
        </DataTable>
      </div>
      {/* 
      {visible && (
        <VolumeModal
          key={new Date()}
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          futVolume={futVolume}
          mcxVolume={mcxVolume}
          foVolume={foVolume}
          mcxLot={mcxLot}
        />
      )}
      {visibleTransactionModal && (
        <TransactionModal
          key={new Date()}
          setVisible={() => setVisibleTransactionModal(false)}
          position={position}
          visible={visibleTransactionModal}
          positionId={positionId}
          reload={() => load_admin_ledger()}
        />
      )} */}
    </div>
  );
}
